.autograde_ongoing_card {
  background: #FFFFFF;
  border-radius: 16px;
  width: 360px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  font-family: "PT Root UI", Arial, sans-serif;
  text-decoration: none;
  margin-top: 8px;
}
@media (max-width: 760px) {
  .autograde_ongoing_card {
    display: block;
    max-width: 360px;
    width: auto;
  }
}
.autograde_ongoing_card__title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}
.autograde_ongoing_card__text {
  font-size: 18px;
  line-height: 24px;
  color: #36363E;
  position: relative;
}
.autograde_ongoing_card__cheops {
  position: absolute;
  right: 3px;
  top: 10px;
  width: 106px;
  height: 108px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_checkmark.svg) 50% 50% no-repeat;
}
.autograde_ongoing_card__task {
  font-size: 16px;
  line-height: 24px;
  color: #7B29C9;
  background: #F1EDFA;
  border-radius: 12px;
  padding: 16px;
  margin-top: 16px;
}
.autograde_ongoing_card__task span {
  float: right;
  color: #36363E;
  font-weight: 600;
}
.autograde_ongoing_card__footer {
  text-align: right;
  font-size: 0;
  margin-top: 36px;
}
.autograde_ongoing_card__button {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background: #F1EDFA;
  border-radius: 8px;
  height: 36px;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #7B29C9;
  margin-left: 8px;
}
.autograde_ongoing_card__button:visited {
  color: #7B29C9;
}
.autograde_ongoing_card__button--green {
  background: linear-gradient(180deg, #8CD100 0%, #66AF31 100%);
  color: #fff;
}
.autograde_ongoing_card__button--green:visited {
  color: #fff;
}
