/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-course-card {
  min-height: 240px;
  background-color: #2979FF;
  border-radius: 16px;
  padding: 24px 40px;
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/course-card-bg.svg);
  background-repeat: no-repeat;
  background-position-x: 87%;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 760px) {
  .teacher-course-card {
    padding: 24px 16px;
  }
}
.teacher-course-card__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 16px;
}
@media (max-width: 760px) {
  .teacher-course-card__wrapper {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 8px;
    column-gap: 0;
  }
}
.teacher-course-card__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  margin: 0 0 12px 0;
}
.teacher-course-card__class-label {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 8px 12px;
  margin: 0 8px 12px 0;
}
.teacher-course-card__class-label-wrapper {
  flex-wrap: wrap;
}
