.element_info_togglers {
  border-radius: 18px;
  color: #7B29C9;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  overflow: hidden;
  display: inline-flex;
}
.element_info_togglers * {
  background: #F1EDFA;
  text-decoration: none;
  color: inherit;
  padding: 8px 16px;
  cursor: pointer;
}
.element_info_togglers *:visited {
  color: inherit;
}
.element_info_togglers *:hover {
  background: #EAE0FF;
}
.element_info_togglers * + * {
  border-left: 1px solid #fff;
}
.element_info_togglers__hints_eye,
.element_info_togglers__hints_eye:hover {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/eye.svg) 50% 50% no-repeat;
  width: 25px;
  height: 16px;
  display: inline-block;
  vertical-align: top;
  margin: auto 10px;
  padding: 2px 0;
  position: relative;
  top: 1px;
}
@media (max-width: 760px) {
  .element_info_togglers {
    display: block;
  }
  .element_info_togglers * {
    display: inline-block;
    margin: 10px 5px;
    border-radius: 18px;
  }
  .element_info_togglers__hints_eye,
  .element_info_togglers__hints_eye:hover {
    margin: auto 10px;
  }
}
