.element_nav_item {
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-block;
  margin: 4px 5px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  font-size: 18px;
  line-height: 34px;
  vertical-align: top;
  color: #fff;
  position: relative;
}
.element_nav_item--active {
  border: 2px solid #FFFFFF;
  padding: 2px;
}
.element_nav_item--disabled {
  background: none !important;
  border: 1px solid #fff;
  padding: 3px;
  cursor: default;
  opacity: 0.2;
  color: #fff;
}
.element_nav_item--disabled > * {
  fill: #FFFFFF;
}
.element_nav_item svg > * {
  fill: #FFFFFF;
}
.module-group-nav .element_nav_item {
  background: #F1EDFA;
  color: #8C8695;
}
.module-group-nav .element_nav_item--active {
  border: 2px solid #8C8695;
}
.module-group-nav .element_nav_item svg > * {
  fill: #8C8695;
}
.element_nav_item--verdict-ok {
  color: #88CC00 !important;
}
.element_nav_item--verdict-ok svg > * {
  fill: #88CC00 !important;
}
.element_nav_item--verdict-partly {
  color: #FFBF00 !important;
}
.element_nav_item--verdict-partly svg > * {
  fill: #FFBF00 !important;
}
.element_nav_item--verdict-wrong {
  color: #FF5E73 !important;
}
.element_nav_item--verdict-wrong svg > * {
  fill: #FF5E73 !important;
}
.element_nav_item--closed {
  color: #fff !important;
}
.element_nav_item--closed svg > * {
  fill: #fff !important;
}
.element_nav_item--closed.element_nav_item--verdict-ok {
  background: #88CC00;
}
.element_nav_item--closed.element_nav_item--verdict-partly {
  background: #FFBF00;
}
.element_nav_item--closed.element_nav_item--verdict-wrong {
  background: #FF5E73;
}
.element_nav_item--closed.element_nav_item--verdict-notReady {
  background: rgba(167, 179, 194, 0.5);
}
.element_nav_item--verdict-unscored:after,
.element_nav_item--has-review-status:after {
  position: absolute;
  bottom: -5px;
  right: -7px;
  content: " ";
  display: block;
}
.element_nav_item--active.element_nav_item--verdict-unscored:after,
.element_nav_item--active.element_nav_item--has-review-status:after {
  bottom: -7px;
  right: -9px;
}
.element_nav_item--active.element_nav_item--review-status-reviewed:after {
  bottom: -4px;
  right: -4px;
}
.element_nav_item--verdict-unscored:after,
.element_nav_item--review-status-onReview:after {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/nav_item_checkmark.svg);
  width: 16px;
  height: 17px;
}
.element_nav_item--review-status-read:after {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/nav_item_checkmark--double.svg);
  width: 24px;
  height: 17px;
}
.element_nav_item--review-status-reviewed:after {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  border-radius: 50%;
}
.element_nav_item--verdict-ok.element_nav_item--review-status-reviewed:after {
  background: #B8E55C;
}
.element_nav_item--verdict-partly.element_nav_item--review-status-reviewed:after {
  background: #FFD966;
}
.element_nav_item--verdict-wrong.element_nav_item--review-status-reviewed:after {
  background: #FFABB6;
}
