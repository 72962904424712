.social_icon {
  display: block;
  height: 48px;
  width: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  text-decoration: none;
}
.social_icon-media-vk {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/social-icon-vk.svg);
}
.social_icon-media-instagram {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/social-icon-instagram.svg);
}
.social_icon-media-youtube {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/social-icon-youtube.svg);
}
.social_icon-media-telegram {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/social-icon-telegram.svg);
}
