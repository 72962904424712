/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
.landings-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 0;
  z-index: 99;
  background: #fcfcf9;
}
.landings-header__back-arrow {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/couse-back-arrow.svg) no-repeat;
  filter: invert(49.5%);
  width: 16px;
  height: 16px;
  position: absolute;
  left: 20px;
  top: 20px;
  box-sizing: content-box;
  cursor: pointer;
}
.landings-header-colored {
  height: 56px;
}
.landings-header-cluster-cpm .landings-header__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm.png);
}
.landings-header-cluster-cpm .landings-header__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-white.png);
}
.landings-header-cluster-cpm .landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-small.svg);
}
.landings-header-cluster-cpm .landings-header__logo-white.landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-small-white.svg);
}
.landings-header-cluster-sirius .landings-header__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius.png);
}
.landings-header-cluster-sirius .landings-header__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-white.png);
}
.landings-header-cluster-sirius .landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-small.svg);
}
.landings-header-cluster-sirius .landings-header__logo-white.landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-small-white.svg);
}
.landings-header-cluster-textbook .landings-header__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-textbook.png);
}
.landings-header-cluster-textbook .landings-header__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-textbook-white.png);
}
.landings-header-cluster-textbook .landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-textbook-small.svg);
}
.landings-header-cluster-textbook .landings-header__logo-white.landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-textbook-small-white.svg);
}
.landings-header-cluster-corp .landings-header__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp.png);
}
.landings-header-cluster-corp .landings-header__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-white.png);
}
.landings-header-cluster-corp .landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-small.svg);
}
.landings-header-cluster-corp .landings-header__logo-white.landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-small-white.svg);
}
.landings-header-cluster-univ .landings-header__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ.png);
}
.landings-header-cluster-univ .landings-header__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-white.png);
}
.landings-header-cluster-univ .landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-small.svg);
}
.landings-header-cluster-univ .landings-header__logo-white.landings-header__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-small-white.svg);
}
.landings-header__services-button {
  margin: 4px auto auto 16px;
  position: fixed;
  top: 0;
  left: 0;
}
.landings-header__logo {
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 196px;
  margin: 11px auto auto 16px;
  display: inline-block;
  vertical-align: top;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}
.landings-header__logo-small {
  width: 35px;
}
.landings-header__logo-additional {
  left: 211px;
}
.landings-header__logo-services {
  position: static;
  margin: 0;
}
@media (max-width: 760px) {
  .landings-header__logo {
    width: 120px;
  }
}
.landings-header__right-widgets {
  display: inline-block;
  vertical-align: top;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}
.landings-header__enter-button {
  background: #7B29C9;
  color: #FFFFFF;
  display: inline-block;
  vertical-align: top;
  margin-top: 12px;
  transition: background 100ms;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 15px;
  line-height: 16px;
  cursor: pointer;
}
.landings-header__enter-button:hover {
  background: #6522A6;
}
.landings-header__menu-button {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/landings_menu_burger.svg) 50% 50% no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 16px 16px 0 24px;
  cursor: pointer;
}
.landings-header__menu-button-black {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/landings_menu_burger_black.svg) 50% 50% no-repeat;
}
.landings-header .services-dropdown:not(.services-dropdown-isTablet) {
  left: 16px;
  margin: 0;
}
@media (max-width: calc(1136px + 16px)) {
  .landings-header .services-dropdown:not(.services-dropdown-isTablet) {
    left: 0;
  }
}
.landings-header a:visited.services-dropdown__service,
.landings-header a.services-dropdown__service {
  color: #1A1A1A;
}
@media (max-width: 760px) {
  .landings-header {
    height: 56px;
  }
}
.course_landing_page .landings_header {
  background: #6c4dc4;
}
