.programming-answer-file-hover .programming-answer__field-wrapper {
  background-color: #EAE0FF;
}
.programming-answer-file-hover .programming-answer__file-upload-link {
  position: static;
}
.programming-answer-file-hover .programming-answer__file-input {
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}
.programming-answer__field-wrapper {
  padding: 16px;
  background-color: #F1EDFA;
  border: 2px solid #C6C2CC;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 16px 0;
  position: relative;
}
.programming-answer__field-wrapper-focused:not(.programming-answer__field-wrapper-has-parts) {
  background-color: #FFF;
}
.programming-answer__field-wrapper-focused {
  border: 2px solid #7B29C9;
}
.programming-answer__field-wrapper-file-uploading:before {
  display: block;
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #EAE0FF;
  z-index: 2;
}
.programming-answer__field-wrapper .MuiInputBase-root {
  font-family: inherit;
}
.programming-answer__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
  color: #36363E;
}
.programming-answer__file-upload-link {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  color: #7B29C9;
  display: flex;
  vertical-align: middle;
  cursor: pointer;
}
.programming-answer__file-upload-link svg {
  margin-left: 10px;
}
.programming-answer__file-input::-webkit-file-upload-button,
.programming-answer__file-input {
  position: absolute;
  top: 0;
  width: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.programming-answer__editor {
  counter-reset: line;
  font-family: 'Source Code Pro', monospace;
  font-size: 16px;
  line-height: 24px;
  min-height: 180px;
}
.programming-answer__editor-solution {
  background-color: transparent;
  border: 0;
}
.programming-answer__editor-wrapper {
  max-height: 350px;
  min-height: 85px;
  overflow: auto;
  scrollbar-color: rgba(169, 169, 169, 0.31) rgba(218, 218, 218, 0.25);
  scrollbar-width: thin;
  margin: 16px -16px;
  padding: 12px 0;
}
.programming-answer__editor-wrapper::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border-radius: 10px;
}
.programming-answer__editor-wrapper::-webkit-scrollbar-track {
  background: rgba(218, 218, 218, 0.25);
}
.programming-answer__editor-wrapper::-webkit-scrollbar-thumb {
  background: rgba(169, 169, 169, 0.31);
  border-radius: 5px;
}
.programming-answer__editor-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 218, 218, 0.45);
}
.programming-answer__editor-wrapper-export {
  max-height: none;
}
.programming-answer__field-wrapper-focused .programming-answer__editor-wrapper {
  background: #fff;
}
.programming-answer__editor-header {
  padding-left: 40px;
  margin: 16px -16px 0;
  white-space: pre-wrap;
}
.programming-answer__editor-footer {
  padding-left: 40px;
  margin: 0 -16px 16px;
  white-space: pre-wrap;
}
.programming-answer__editor-textarea {
  outline: none;
}
.programming-answer__editor-line-number:before {
  position: absolute;
  left: 0;
  text-align: right;
  opacity: 0.3;
  user-select: none;
  counter-increment: line;
  content: counter(line);
  width: 30px;
}
.programming-answer__expand-button {
  align-items: center;
  text-align: center;
  color: #7B29C9;
  border-radius: 12px;
  background: #F1EDFA;
  padding: 16px;
  cursor: pointer;
  margin: 16px 0;
}
.programming-answer__expand-button-verdict-wrong {
  background: #FFEBED;
}
.programming-answer__expand-button-verdict-ok {
  background: #EBF7DF;
}
.programming-answer__expand-button-verdict-partly {
  background: #FFF5D9;
}
.programming-answer__lang-select {
  position: absolute !important;
  top: 13px;
  right: 13px !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 24px;
  color: #36363E;
}
.programming-answer__lang-select:after {
  display: none;
}
.programming-answer__lang-select:before {
  display: none;
}
.programming-answer-solution {
  margin-bottom: 20px;
  border-radius: 12px;
  overflow: hidden;
}
.programming-answer-solution__editor-wrapper {
  border: 1px solid #E1DFE6;
  border-radius: 12px;
  position: relative;
  padding-top: 34px;
  overflow: hidden;
}
.programming-answer-solution__editor-textarea-wrapper {
  overflow: auto;
  max-height: 350px;
  min-height: 180px;
}
.programming-answer-solution__footer {
  padding: 12px 16px;
  border-top: 1px solid #E1DFE6;
  background: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.programming-answer-solution__footer img {
  vertical-align: top;
  margin-right: 30px;
  cursor: pointer;
}
.programming-answer-solution__footer img:hover {
  cursor: pointer;
}
.programming-answer-solution__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
  overflow: hidden;
}
.programming-answer-solution__title-time {
  color: #8C8695;
  font-weight: 300;
}
.programming-answer-solution__verdict {
  color: #8C8695;
  float: right;
  font-weight: 300;
}
.programming-answer-solution__score {
  font-weight: 900;
  font-size: 16px;
  color: #36363E;
  float: right;
}
.programming-answer-solution__score .programming-answer__expand-button {
  color: #36363E;
}
.programming-answer-solution__check {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  margin-left: 8px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/icon_check.svg);
  width: 24px;
  height: 24px;
}
.programming-answer-solution__check-double {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/icon_double_check.svg);
}
.programming-answer-solution__error {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  background: #36363E;
  padding: 16px;
  box-sizing: border-box;
  margin: 12px -16px -16px;
}
.programming-answer-solution__error > div {
  font-weight: normal;
  font-family: 'Source Code Pro', monospace;
  padding-left: 32px;
}
.programming-answer-solution__error-description {
  color: #fff;
  margin: 10px -16px -16px -16px;
  background: #4f4f4f;
  border-radius: 0 0 12px 12px;
  padding: 16px !important;
  font-size: 16px;
  line-height: 24px;
}
.programming-answer-solution__error-description pre {
  padding-left: 20px;
  color: white;
  font-family: 'Source Code Pro', monospace !important;
}
.programming-answer-solution__code-header {
  padding-left: 40px;
  margin: 0 0 16px;
}
.programming-answer-solution__code-footer {
  padding-left: 40px;
  margin: 16px 0;
}
.programming-answer-solution-collapsed {
  cursor: pointer;
}
.programming-answer-solution-verdict-wrong .programming-answer-solution__editor-wrapper {
  background: #FFEBED;
}
.programming-answer-solution-verdict-ok .programming-answer-solution__editor-wrapper {
  background: #EBF7DF;
}
.programming-answer-solution-verdict-partly .programming-answer-solution__editor-wrapper {
  background: #FFF5D9;
}
.programming-answer-solution-collapsed.programming-answer-solution-verdict-wrong .programming-answer-solution__title {
  background: #FFEBED;
}
.programming-answer-solution-collapsed.programming-answer-solution-verdict-ok .programming-answer-solution__title {
  background: #EBF7DF;
}
.programming-answer-solution-collapsed.programming-answer-solution-verdict-partly .programming-answer-solution__title {
  background: #FFF5D9;
}
.MuiMenuItem-root.MuiButtonBase-root {
  font-size: 14px;
  font-weight: 300;
  font-family: "PT Root UI", Arial, sans-serif;
}
