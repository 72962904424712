@font-face {
  font-family: "PT Root UI";
  font-weight: 700;
  font-style: normal;
  src: local('PT Root UI Bold'), local('PT-Root-UI-Bold'), local('PTRootUI-Bold'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/PT-Root-UI_Bold.woff2) format('woff2'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/PT-Root-UI_Bold.woff) format('woff');
}
@font-face {
  font-family: "PT Root UI";
  font-weight: 500;
  font-style: normal;
  src: local('PT Root UI Medium'), local('PT-Root-UI-Medium'), local('PTRootUI-Medium'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/PT-Root-UI_Medium.woff2) format('woff2'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/PT-Root-UI_Medium.woff) format('woff');
}
@font-face {
  font-family: "PT Root UI";
  font-weight: 400;
  font-style: normal;
  src: local('PT Root UI'), local('PT-Root-UI'), local('PTRootUI'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/PT-Root-UI_Regular.woff2) format('woff2'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/PT-Root-UI_Regular.woff) format('woff');
}
/* Generated with js2less.js.
Original file: src/styles/values/text-sizes.json */
.text-xxl {
  font-size: 32px;
  line-height: 40px;
}
.text-xl {
  font-size: 24px;
  line-height: 32px;
}
.text-l {
  font-size: 18px;
  line-height: 28px;
}
.text-m {
  font-size: 16px;
  line-height: 24px;
}
.text-s {
  font-size: 14px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 16px;
}
.text-xxs {
  font-size: 10px;
  line-height: 12px;
}
/* Generated with js2less.js.
Original file: src/styles/values/text-weight.json */
.text-thin {
  font-weight: 300;
}
.text-bold {
  font-weight: 500;
}
.text-extra-bold {
  font-weight: 700;
}
@font-face {
  font-family: 'Alice';
  src: url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/Alice-Regular.woff2) format('woff2'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/Alice-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'LabGrotesque';
  src: url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/LabGrotesque-Black.woff2) format('woff2'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/LabGrotesque-Black.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'LabGrotesque';
  src: url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/LabGrotesque-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'LabGrotesque';
  src: url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/LabGrotesque-Light.woff2) format('woff2'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/LabGrotesque-Light.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Source Code Pro';
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  src: local('Source Code Pro ExtraLight'), local('SourceCodePro-ExtraLight'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/SourceCodePro-ExtraLight.ttf.woff2) format('woff2');
}
@font-face {
  font-family: 'Source Code Pro';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: local('Source Code Pro Light'), local('SourceCodePro-Light'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/SourceCodePro-Light.ttf.woff2) format('woff2');
}
@font-face {
  font-family: 'Source Code Pro';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: local('Source Code Pro Regular'), local('SourceCodePro-Regular'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/SourceCodePro-Regular.ttf.woff2) format('woff2');
}
@font-face {
  font-family: 'Source Code Pro';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  src: local('Source Code Pro Medium'), local('SourceCodePro-Medium'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/SourceCodePro-Medium.ttf.woff2) format('woff2');
}
@font-face {
  font-family: 'Source Code Pro';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  src: local('Source Code Pro Semibold'), local('SourceCodePro-Semibold'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/SourceCodePro-Semibold.ttf.woff2) format('woff2');
}
@font-face {
  font-family: 'Source Code Pro';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: local('Source Code Pro Bold'), local('SourceCodePro-Bold'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/SourceCodePro-Bold.ttf.woff2) format('woff2');
}
@font-face {
  font-family: 'Source Code Pro';
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  src: local('Source Code Pro Black'), local('SourceCodePro-Black'), url(/347e4c1c42194a73942851ed06937e9284512310/assets/fonts/SourceCodePro-Black.ttf.woff2) format('woff2');
}
.cluster-social-og-corp {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-social-og-corp.png);
}
.cluster-social-og-cpm {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-social-og-cpm.png);
}
.cluster-social-og-sirius {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-social-og-sirius.png);
}
.cluster-social-og-univ {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-social-og-univ.png);
}
/** Colors **/
/*names from figma*/
/* Главная кнопка */
/* Неверно */
/** **/
/** **/
html {
  font-size: 14px;
}
#index {
  width: 100%;
  height: 100%;
}
body {
  font-family: "PT Root UI", Arial, sans-serif;
  margin: 0;
  overflow: auto scroll;
}
body.mouseover {
  cursor: pointer;
}
a {
  color: #2F80ED;
}
a:visited {
  color: #2F80ED;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.d-block {
  display: block;
}
.parent_mode_text,
.lock_bar {
  background-color: #000000;
  border-radius: 10px;
  position: fixed;
  bottom: 5px;
  left: 5px;
  right: 5px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  font-size: 12px;
  z-index: 1000;
}
.lock_bar {
  padding: 0 22px;
}
.lock_bar__prof_img {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_advising.svg) no-repeat;
  width: 56px;
  height: 65px;
  display: inline-block;
  vertical-align: top;
}
.lock_bar__prof_text {
  display: inline-block;
  vertical-align: top;
  max-width: 290px;
  width: calc(100% - 56px);
  text-align: left;
}
.lock_bar__prof_advice_text {
  margin: 22px auto;
  background: #E1DDF0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px 32px 32px 32px;
  padding: 25px 36px 20px 16px;
  box-sizing: border-box;
  display: inline-block;
}
.lock_bar__prof_advice_text h3 {
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.01em;
  color: #9194A1;
  padding: 0;
  margin: 0;
}
.lock_bar__prof_advice_text p {
  line-height: 18px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #000000;
  padding: 0;
  margin: 0;
}
@media (max-width: 760px) {
  .lock_bar__prof_advice_text {
    margin-top: 40px;
  }
}
.lock_bar__close {
  position: absolute;
  top: 12px;
  right: 17px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.lock_bar__wrapper {
  padding: 16px 0;
}
@media (max-width: 760px) {
  .lock_bar__wrapper {
    padding: 16px 0 32px;
  }
}
.mathjax_container iframe {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1.77777778;
}
.mathjax_container img {
  max-width: 100%;
}
@media (max-width: 760px) {
  .mathjax_container iframe {
    border-radius: 6px;
    width: 100%;
    aspect-ratio: 1.77777778;
  }
}
_ {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/sirius_courses_logo.png);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cheops_hello.png);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_not_found.png);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_nothing.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/icons.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/social_open_graph.png);
}
.course_page {
  background: #d3dff1;
  width: 100%;
  height: 100%;
  font-size: 0;
  padding-bottom: 132px;
}
.course_page--dark-overlay:before {
  content: ' ';
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.course_page--cards {
  min-height: calc(100vh - 132px);
  background: #18181A;
  position: relative;
}
.course_page--cards .landings_footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.course_page--cards .module_card {
  position: static;
}
.course_page__plain_module_list {
  padding-top: 28px;
  padding-bottom: 41px;
  margin-left: 396px;
  margin-right: 16px;
}
.course_page__plain_module_list .module_card {
  margin: 8px;
  width: calc(20% - (8px * 2));
}
.course_page__plain_module_list_textbook {
  padding-top: 8px;
  margin-left: 393px;
  margin-right: 0;
}
@media (max-width: 2000px) {
  .course_page__plain_module_list .module_card {
    width: calc(25% - (8px * 2));
  }
}
@media (max-width: 1700px) {
  .course_page__plain_module_list .module_card {
    width: calc(33% - (8px * 2));
  }
}
@media (max-width: 1370px) {
  .course_page__plain_module_list {
    margin-right: 16px;
  }
  .course_page__plain_module_list .module_card {
    width: calc(50% - (8px * 2));
  }
}
@media (max-width: 1050px) {
  .course_page__plain_module_list .module_card {
    width: 100%;
  }
}
@media (max-width: 760px) {
  .course_page__plain_module_list {
    padding-top: 56px;
  }
}
@media (max-width: 760px) {
  .course_page__plain_module_list {
    margin-left: auto;
    margin-right: auto;
  }
  .course_page__plain_module_list .module_card {
    width: calc(100% - (8px * 2));
  }
}
.course_page__plain_module_list_title {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 26px;
  margin-bottom: 22px;
}
@media (max-width: 760px) {
  .course_page__plain_module_list_title {
    max-width: 360px;
    width: calc(100% - 20px);
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
.course_page__waiting {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.course_page__waiting_text {
  margin-top: 88px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}
.course_page .module_action_card {
  margin-top: 10px;
}
@media (max-width: 760px) {
  .course_page:not(.course_page--cards) .course_progress_form {
    position: fixed;
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    max-width: none;
    max-height: 80vh;
  }
  .course_page:not(.course_page--cards) .module_card {
    border-radius: 0;
  }
  .course_page:not(.course_page--cards) .module_card__wrapper {
    border-radius: 16px 16px 0 0;
  }
  .course_page__waiting_text {
    margin: 124px 32px 0 32px;
  }
}
.course_cards {
  width: 100%;
  height: 100%;
}
@media (max-width: 760px) {
  .course_header--reduced .course_header__title {
    visibility: hidden;
  }
}
.course_header {
  background: #6c4dc4;
  width: 100%;
  height: 140px;
  position: relative;
  transition: height 0.65s;
}
.course_header--rounded {
  border-radius: 0 0 16px 16px;
}
.course_header ~ .graph {
  transition: height 1s, top 0.65s;
}
.course_header ~ .course_module_list {
  transition: top 0.65s;
}
.course_header ~ .module_card {
  transition: top 0.65s;
}
.course_header--expanded {
  height: 140px;
}
.course_header--expanded ~ .graph {
  height: calc(100% - 140px);
  top: 140px;
}
.course_header--expanded ~ .course_module_list {
  top: 156px;
}
.course_header--expanded ~ .course_module_list-opened .course_module_list__wrapper {
  max-height: calc(100vh - 226px);
}
.course_header--expanded ~ .course_module_list-opened .course_module_list__modules {
  max-height: calc(100vh - 326px);
}
.course_header--expanded ~ .module_card {
  top: 156px;
}
.course_header--expanded-menu {
  height: 190px;
}
.course_header--expanded-menu ~ .graph {
  height: calc(100% - 190px);
  top: 190px;
}
.course_header--expanded-menu ~ .course_module_list {
  top: 200px;
}
.course_header--expanded-menu ~ .course_module_list-opened .course_module_list__wrapper {
  max-height: calc(100vh - 270px);
}
.course_header--expanded-menu ~ .course_module_list-opened .course_module_list__modules {
  max-height: calc(100vh - 370px);
}
.course_header--expanded-menu ~ .module_card {
  top: 200px;
}
.course_header--reduced {
  height: 56px;
}
.course_header--reduced .course_header__title {
  font-size: 16px;
  padding: 10px 64px;
}
.course_header--reduced .course_header__title--center {
  text-align: center;
  padding: 10px 40px;
}
.course_header--reduced .course_header__title--left {
  text-align: left;
}
.course_header--reduced ~ .graph {
  top: 56px;
}
.course_header--reduced ~ .course_module_list {
  top: 72px;
}
.course_header--reduced .course_header__menu {
  visibility: hidden;
  height: 0;
  width: 0;
}
.course_header--reduced .course_header__menu a {
  visibility: hidden;
  height: 0;
  width: 0;
}
.course_header .course_numbers {
  height: 14px;
  font-size: 11px;
  color: #ffffff;
  text-align: center;
  padding: 0 4px;
  border-radius: 4px;
}
.course_header .course_numbers--red {
  background: #FF5E73;
}
.course_header .course_numbers--green {
  background: #5bc36c;
}
.course_header .course_back_arrow {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/couse-back-arrow.svg) no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 20px;
  top: 20px;
  box-sizing: content-box;
  cursor: pointer;
}
.course_header--transparent {
  background: transparent;
}
.course_header--transparent .course_back_arrow {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/auth_back_arrow.svg) 50% 50% no-repeat;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 16px;
  top: 16px;
  cursor: pointer;
}
.course_header__title {
  line-height: 36px;
  font-size: 36px;
  font-weight: 400;
  height: 134px;
  color: #ffffff;
  margin: 0;
  padding: 60px 36px 0 64px;
  box-sizing: border-box;
  transition: padding 0.65s, font-size 0.65s;
}
@media (max-width: 760px) {
  .course_header__title {
    line-height: 24px;
    font-size: 24px;
    letter-spacing: -0.01em;
  }
}
.course_header--white {
  background: #ffffff;
  height: 56px;
}
.course_header--white .course_header__title {
  color: #232943;
}
.course_header--white .course_back_arrow {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/auth_back_arrow.svg) 50% 50% no-repeat;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 16px;
  top: 16px;
  cursor: pointer;
}
@media (max-width: 760px) {
  .course_header--white {
    height: 56px;
  }
  .course_header--white .course_header__title {
    line-height: 36px;
  }
}
.course_header--pinned {
  position: fixed;
}
.course_header__widgets {
  position: absolute;
  right: 14px;
  top: 0;
  height: 100%;
  max-height: 56px;
  width: auto;
  box-sizing: content-box;
  font-size: 0;
}
.course_header__menu_button {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/menu_button.svg) 50% 50% no-repeat;
  width: 24px;
  height: 24px;
  margin-top: 15px;
  cursor: pointer;
  display: inline-block;
  transition: width 0.4s;
}
.course_header__menu_button--hidden {
  width: 0;
}
.course_header .notifications_icon {
  margin-right: 22px;
  vertical-align: top;
}
.course_header .course_header__progress {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  margin-left: 0;
  margin-right: 22px;
  width: auto;
  height: 19px;
  font-size: 11px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
}
.course_header .course_header__progress svg {
  vertical-align: text-bottom;
  margin-right: 5px;
}
.course_header__menu {
  text-align: left;
  font-size: 0;
  white-space: nowrap;
  overflow: auto;
}
a.course_header__menu_item {
  text-align: center;
  line-height: 37px;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.5;
  text-decoration: none;
  height: 40px;
  display: inline-block;
  padding: 7px 25px;
}
a.course_header__menu_item--active {
  opacity: 1;
  border-bottom: 2px solid #fff;
  color: #fff;
}
.course_header__main_menu {
  text-align: left;
  font-size: 0;
  white-space: nowrap;
  overflow: auto;
  padding-left: 64px;
}
@media (max-width: 760px) {
  .course_header__main_menu {
    padding-left: 10px;
  }
}
a.course_header__main_menu_item {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  padding: 9px 25px;
  background: #7a57de;
  border-radius: 12px;
  margin-right: 10px;
  opacity: 0;
  height: 0;
  transition: all 0.4s;
}
a.course_header__main_menu_item:hover {
  background: #8860f7;
}
.course_header__main_menu--expanded a.course_header__main_menu_item {
  opacity: 1;
  height: 19px;
}
.course_info_page__wrapper {
  margin: 0 auto;
  max-width: 600px;
  padding-top: 100px;
  padding-bottom: 294px;
}
.course_info_page__title {
  font-style: normal;
  font-weight: normal;
  line-height: 44px;
  font-size: 36px;
  letter-spacing: -0.01em;
  color: #232943;
}
.course_info_page__time {
  line-height: 18px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #9194a1;
  margin-top: 12px;
}
.course_info_page__content {
  margin-top: 40px;
  line-height: 28px;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #232943;
}
.course_info_page__content a {
  text-decoration: none;
  color: #4b8cee;
}
.course_info_page__button {
  text-decoration: none;
  background: linear-gradient(245.31deg, #40ccfb 48.72%, #4b8cee 98.18%), #56ccf2;
  background-size: 200%;
  background-position: 0 50%;
  transition: all 500ms, background 100ms;
  display: block;
  color: #fff !important;
  text-transform: uppercase;
  width: 340px;
  padding: 20px;
  border-radius: 28px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin: 70px auto 0;
}
.course_info_page__button:hover {
  background-position: 100% 50%;
}
.course_info_page__button:active {
  background: #4b8eee;
}
.course_info_page__button:disabled {
  background: #e3e5e8;
  cursor: default;
}
@media (max-width: 760px) {
  .course_info_page__button {
    max-width: 340px;
    width: calc(100% - 16px);
  }
}
@media (max-width: 760px) {
  .course_info_page__wrapper {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
}
.graph {
  position: fixed;
  left: 375px;
  right: 0;
  top: 0;
  bottom: 0;
}
.graph__container {
  background: #18181A;
  width: inherit;
  height: 100%;
}
.graph__controls {
  position: absolute;
  right: 15px;
  top: 50%;
  width: 60px;
  height: 180px;
  margin-top: -90px;
}
@media (max-width: 768px) {
  .graph__controls {
    right: 0;
    width: 47px;
  }
}
.graph__controls_zoom {
  width: 48px;
  background: #fff;
  height: 100px;
  box-shadow: 0 2px 6px rgba(64, 77, 92, 0.35);
  border-radius: 24px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .graph__controls_zoom {
    width: 36px;
    height: 75px;
  }
}
.graph__controls_svg_wrapper {
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: 100%;
  position: relative;
}
.graph__controls_zoom_in {
  cursor: pointer;
  width: 48px;
  height: 55px;
  text-align: center;
  box-sizing: border-box;
}
.graph__controls_zoom_in svg {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 11px;
  left: 11px;
}
.graph__controls_zoom_in:hover {
  background: #ececec;
}
@media (max-width: 768px) {
  .graph__controls_zoom_in {
    width: 36px;
    height: 38px;
    padding: 0;
  }
  .graph__controls_zoom_in svg {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 9px;
    top: 10px;
  }
}
.graph__controls_zoom_out {
  cursor: pointer;
  width: 48px;
  height: 50px;
  text-align: center;
  box-sizing: border-box;
}
.graph__controls_zoom_out svg {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: -35px;
  left: 11px;
}
.graph__controls_zoom_out:hover {
  background: #ececec;
}
@media (max-width: 768px) {
  .graph__controls_zoom_out {
    width: 36px;
    height: 37px;
  }
  .graph__controls_zoom_out svg {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 9px;
    bottom: -25px;
  }
}
.graph__controls_to_center {
  cursor: pointer;
  width: 48px;
  background: #fff;
  height: 48px;
  box-shadow: 0 2px 6px rgba(64, 77, 92, 0.35);
  border-radius: 50%;
  overflow: hidden;
  margin-top: 20px;
  box-sizing: border-box;
}
.graph__controls_to_center svg {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 11px;
  left: 8px;
}
.graph__controls_to_center:hover {
  background: #ececec;
}
@media (max-width: 768px) {
  .graph__controls_to_center {
    width: 36px;
    height: 36px;
  }
  .graph__controls_to_center svg {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 8px;
    top: 10px;
  }
}
.graph__controls_copy_paste_modules {
  width: 48px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(64, 77, 92, 0.35);
  border-radius: 24px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  right: 30px;
  height: 48px;
  padding: 11px;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 2;
}
.graph__controls_copy_paste_modules svg {
  width: 25px;
  height: 25px;
}
.graph__controls_copy_paste_modules:hover {
  background: #ececec;
}
.graph--locked .graph__container {
  background: #2f2f33;
}
@media (max-width: 760px) {
  .graph {
    left: 0;
  }
}
_ {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_selected.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_small.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_autograde.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_small_selected.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_course_bg.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_green.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_blue.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_red.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_orange.svg);
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/graph_marker_purple.svg);
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.auth_page,
.auth_confirmation {
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;
  background-color: #6c4dc4;
}
.auth_page-textbook,
.auth_confirmation-textbook {
  background-color: #FFFFFF;
}
.auth_page__back_arrow,
.auth_confirmation__back_arrow {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/auth_back_arrow.svg) no-repeat;
  width: 16px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
}
.auth_footer {
  color: #FFFFFF;
  font-family: Helvetica Neue, sans-serif;
  height: 170px;
  width: 100%;
  position: relative;
  margin-top: -50px;
  bottom: 0;
  z-index: -1;
}
.auth_footer__lyceum {
  line-height: 30px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 0;
  padding: 0;
  padding-top: 64px;
}
.auth_footer__top {
  height: 110px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background: #6C4DC4;
  box-shadow: 0px -5px 25px rgba(0, 0, 0, 0.5);
}
.auth_footer__bottom {
  background: #3F2688;
  height: 60px;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 15px;
  box-sizing: border-box;
  text-align: center;
  color: #FFFFFF;
  line-height: 30px;
  font-size: 14px;
  letter-spacing: 0.02em;
}
.auth_footer__bottom h1 {
  padding-top: 13px;
}
@media (max-width: 760px) {
  .auth_footer__top_text {
    display: none;
  }
}
.auth_content {
  background: #FCFCF9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 16px 16px;
  min-height: calc(100vh - 132px);
  overflow: auto;
  position: relative;
}
.auth_content-textbook {
  min-height: 100vh;
}
.auth_content__resend_code {
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #828282;
  width: 300px;
  margin: 30px auto 10px;
}
.auth_content__resend_code_button {
  color: #2F80ED;
  cursor: pointer;
  display: inline-block;
}
.auth_content__change_email {
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #2F80ED;
  margin-top: 9px;
  display: inline-block;
}
.auth_description {
  margin: 0 auto;
  width: 300px;
  padding: 12vh 0 0 20px;
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  font-size: 24px;
  letter-spacing: -0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auth_description--confirmation {
  font-size: 18px;
  line-height: 24px;
}
.auth_form {
  padding-top: 30px;
  padding-bottom: 76px;
}
.auth_form--new {
  min-height: 1270px;
}
.auth_form__error {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  color: #FF5E73;
  min-height: 20px;
  margin: 5px auto;
  max-width: 300px;
}
.auth_form__profile {
  text-align: center;
  padding-top: 30px;
}
.auth_form__profile .auth_form_input_text:first-child {
  margin-top: 20px;
}
.auth_form__profile_group--active .auth_form_input_text {
  height: 0;
  opacity: 0;
}
.auth_form__profile_title {
  max-width: 290px;
  text-align: left;
  margin: 0 auto;
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  font-size: 24px;
  letter-spacing: -0.01em;
  color: #000000;
}
.auth_form__profile_cheops {
  width: 224px;
  height: 145px;
  margin: 22px auto 0 auto;
  position: relative;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_happy.png) 0 100% no-repeat;
  background-size: 100px;
}
.auth_form__profile_cheops_introduction {
  background: #E1DDF0;
  border-radius: 16px 16px 16px 4px;
  padding: 9px 12px;
  margin-left: 95px;
  margin-right: auto;
  text-align: left;
}
.auth_form__profile_cheops_introduction_text,
.auth_form__profile_group_question_text {
  font-family: "PT Root UI", Arial, sans-serif;
  line-height: 16px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #000000;
  opacity: 0.5;
}
.auth_form__profile_cheops_circle_big {
  width: 12px;
  height: 12px;
  background: #CDC7E5;
  border-radius: 50%;
  position: absolute;
  top: 44px;
  right: 121px;
}
.auth_form__profile_cheops_circle_small {
  width: 6px;
  height: 6px;
  background: #CDC7E5;
  border-radius: 50%;
  position: absolute;
  top: 55px;
  right: 132px;
}
.auth_form__profile_group_question {
  background: #E1DDF0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 16px;
  padding: 9px 12px;
  margin: 27px auto 17px auto;
  transition: all 0.2s;
}
.auth_form__profile_group_question_circle_big {
  width: 12px;
  height: 12px;
  background: #CDC7E5;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  left: 50%;
  margin-left: -6px;
}
.auth_form__profile_group_question_circle_small {
  width: 6px;
  height: 6px;
  background: #CDC7E5;
  border-radius: 50%;
  position: absolute;
  bottom: -13px;
  left: 50%;
  margin-left: -3px;
}
.auth_form__profile_group--active .auth_form__profile_group_question {
  height: 0;
  opacity: 0;
}
.auth_form__profile_nav {
  width: 300px;
  display: inline-block;
  padding: 1px;
  border-radius: 16px;
  background: #2F80ED;
  font-size: 0;
  transition: all 500ms;
}
.auth_form__profile_nav_item {
  display: inline-block;
  vertical-align: top;
  line-height: 22px;
  font-size: 12px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 5px;
  color: #FFFFFF;
  cursor: pointer;
  height: 30px;
  transition: all 500ms;
  box-sizing: border-box;
}
.auth_form__profile_nav_item:first-child {
  width: 38%;
}
.auth_form__profile_nav_item:nth-child(2) {
  width: 30%;
}
.auth_form__profile_nav_item:nth-child(3) {
  width: 32%;
}
.auth_form__profile_nav_item--active {
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 16px;
  color: #000000;
}
.auth_form_input_text {
  min-height: 48px;
  width: 300px;
  margin: 10px auto;
  font-size: 14px;
  transition: all 0.2s;
}
.auth_form_input_text input,
.auth_form_input_text select,
.auth_form_input_text .auth_input_school_selected {
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 24px;
  text-align: center;
  outline: none;
  font-size: 14px;
}
.auth_form_input_text select {
  padding-left: 20px;
}
.auth_form_input_text input.valid,
.auth_form_input_text select.valid {
  border: 2px solid #4B8CEE;
}
.auth_form_input_text input.invalid,
.auth_form_input_text select.invalid {
  border: 2px solid #FF5E73;
}
.auth_form_input_text input::placeholder,
.auth_form_input_text input::-ms-input-placeholder {
  line-height: 20px;
  font-size: 18px;
  letter-spacing: -0.01em;
  color: #828282;
}
.auth_form_input_text input::-webkit-outer-spin-button,
.auth_form_input_text input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.auth_form_input_text input[type=number] {
  -moz-appearance: textfield;
}
.auth_form_input_text--has-error input {
  border: 2px solid #FF5E73;
}
.auth_form_input_text .auth_input_region--empty {
  line-height: 20px;
  font-size: 18px;
  letter-spacing: -0.01em;
  color: #828282;
}
#school_select .auth_input_school_selected,
#school_select2 .auth_input_school_selected {
  min-height: 48px;
  height: auto;
  padding: 15px 5px;
}
#school_select .auth_input_school_selected + input,
#school_select2 .auth_input_school_selected + input {
  display: none;
}
#school_select .auth_input_school_selected span,
#school_select2 .auth_input_school_selected span {
  color: #828282;
}
#school_select #__listItemProps,
#school_select2 #__listItemProps {
  background: #fff;
  position: relative;
  z-index: 5;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  text-align: left;
  line-height: 18px;
  font-size: 14px;
  padding: 12px;
  max-height: 255px;
}
#school_select #__listItemProps > div,
#school_select2 #__listItemProps > div {
  margin: 15px 0;
  cursor: pointer;
}
#school_select #__listItemProps > div:first-child,
#school_select2 #__listItemProps > div:first-child {
  margin-top: 0;
}
#school_select #__listItemProps > div:last-child,
#school_select2 #__listItemProps > div:last-child {
  margin-bottom: 0;
}
#school_select #__listItemProps > div span,
#school_select2 #__listItemProps > div span {
  color: #828282;
}
.dp-modal .dp {
  padding-top: 0;
  width: calc(100% - 20px) !important;
}
.dp-modal .dp:before {
  content: none;
}
.dp-modal .dp-col-header,
.dp-modal .dp-cal-footer {
  visibility: hidden;
  height: 0;
}
.auth_form_button {
  background: #E0E0E0;
  box-shadow: 0 4px 8px rgba(35, 41, 67, 0.25);
  border-radius: 28px;
  width: 300px;
  height: 48px;
  margin: 0 auto;
  box-sizing: border-box;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.auth_form_button--enabled {
  background: linear-gradient(245.31deg, #40CCFB 48.72%, #4B8CEE 98.18%), #56CCF2;
  background-size: 200%;
  background-position: 0 50%;
  transition: all 500ms, background 100ms;
}
.auth_form_button--enabled:hover {
  background-position: 100% 50%;
}
.auth_form_button--enabled:active {
  background: #4B8EEE;
}
.auth_form_button h1 {
  margin: 0;
  padding: 16px 0 0;
  font-size: 14px;
  font-weight: normal;
}
.auth_auxiliary {
  margin: 10px auto 0 auto;
  width: 300px;
  color: #828282;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  padding-bottom: 20px;
}
.auth_auxiliary a {
  text-decoration: none;
  color: #2F80ED;
}
.auth_auxiliary .valid {
  background: linear-gradient(255.89deg, #40CCFB 0%, #4B8CEE 100%), #4B8CEE;
}
.auth_change_email,
.auth_send_mail_again {
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
}
.auth_change_email,
.auth_send_mail_again--ready {
  color: #2F80ED;
}
.dp-cal-month,
.dp-cal-year {
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}
.dp-cal-month:after,
.dp-cal-year:after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 16px;
  font-size: 0.6em;
  line-height: 2.2em;
}
.dp-cal-month {
  margin-right: 16px;
}
.module_card_rating {
  padding-left: 64px;
  padding-top: 16px;
}
@media (max-width: 760px) {
  .module_card_rating {
    display: none;
  }
}
.module_card_rating h1 {
  color: #FFFFFF;
  opacity: 0.5;
  font-weight: normal;
  line-height: 18px;
  font-size: 12px;
  letter-spacing: 0.02em;
  margin: 0;
  padding: 0;
}
.module_card_rating ul {
  font-size: 0;
  margin: 7px 0 0 0;
  padding: 0;
}
.module_card_rating ul li {
  list-style: none;
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;
}
.module_card_rating ul :last-child {
  margin-right: 0;
}
.module_card_rating__star {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/rating_star.svg) center no-repeat;
  width: 24px;
  height: 24px;
}
.module_card_rating__star--starred {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/rating_star_starred.svg) center no-repeat;
}
.module_card_required_item {
  margin: 0 16px;
  width: calc(100% - 32px);
  height: 80px;
  border-top: 1px solid #DDE4ED;
  position: relative;
  cursor: pointer;
  padding-right: 50px;
  box-sizing: border-box;
  transition: all 0.4s;
}
.module_card_required_item__title {
  padding-top: 14px;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #232943;
}
.module_card_required_item svg {
  position: absolute;
  top: 11px;
  right: 0;
}
@media (max-width: 760px) {
  .module_card_required_item {
    visibility: hidden;
    height: 0;
  }
}
.contest_solution_popup {
  width: 300px;
  height: 260px;
  position: fixed;
  left: 50%;
  margin-left: -145px;
  top: 180px;
  z-index: 100;
}
.contest_solution_popup__circle {
  position: absolute;
  background: linear-gradient(180deg, #00C2C2 0%, #17B861 100%), #5BC36C;
  box-shadow: 0 10px 50px rgba(64, 77, 92, 0.25);
  border-radius: 120px;
  opacity: 0.5;
}
.contest_solution_popup__circle--wrong {
  background: linear-gradient(180deg, #E41FD8 0%, #F4425A 100%), #F4425A;
}
.contest_solution_popup__circle_title {
  width: 100%;
  top: 0;
  position: absolute;
  line-height: 36px;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  padding-top: 100px;
}
.contest_solution_popup__circle_title--false {
  padding-top: 82px;
}
.contest_solution_popup__circle_title_attempts {
  font-size: 20px;
}
.contest_solution_popup__circle--big {
  width: 240px;
  height: 240px;
  top: 0;
  left: 25px;
  opacity: 1;
}
.contest_solution_popup__circle--top {
  left: 174px;
  top: 0;
  width: 122px;
  height: 122px;
}
.contest_solution_popup__circle--top--wrong {
  width: 145px;
  height: 145px;
  left: 0;
}
.contest_solution_popup__circle--bottom {
  left: 0;
  bottom: 0;
  width: 162px;
  height: 162px;
}
.contest_solution_popup__circle--bottom--wrong {
  width: 79px;
  height: 79px;
  left: 194px;
}
.module_passing_page {
  background: #6C4DC4;
  position: relative;
  width: 100%;
}
.module_passing_footer {
  color: #FFFFFF;
  font-family: Helvetica Neue, sans-serif;
  height: 120px;
  width: 100%;
}
.module_passing_footer h1 {
  line-height: 30px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 0;
  padding: 0;
  padding-top: 14px;
}
.module_passing_footer__top {
  height: 60px;
  width: 100%;
  z-index: 1;
}
@media (max-width: 760px) {
  .module_passing_footer__top_text {
    display: none;
  }
}
.module_passing_footer__bottom {
  background: #3F2688;
  height: 60px;
  width: 100%;
}
.module_passing_footer__bottom h1 {
  padding-top: 13px;
}
.module_passing_header {
  min-height: 56px;
}
.module_passing_header .element_nav_item {
  background: #7a57de;
}
.module_passing_content,
.module-video-content {
  background: #FFFFFF;
  width: 100%;
  position: relative;
  min-height: calc(100vh - 188px);
  padding-bottom: 120px;
}
.module_passing_content__title,
.module-video-content__title {
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 16px;
}
.module_passing_content__wrapper,
.module-video-content__wrapper {
  max-width: 810px;
  margin: 0 auto;
  padding: 30px 45px;
}
@media (max-width: 760px) {
  .module_passing_content__wrapper,
  .module-video-content__wrapper {
    padding: 30px 16px;
    width: 100%;
    box-sizing: border-box;
  }
}
.module_passing_content__qa_link,
.module-video-content__qa_link {
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  color: #9194A1;
  text-decoration: none;
  border: 1px solid #F2F2F2;
  border-radius: 16px;
  padding: 6px 22px;
  display: inline-block;
  box-sizing: border-box;
  outline: none;
}
.module_passing_content__qa_link:focus,
.module-video-content__qa_link:focus {
  border: 2px solid #4B8CEE;
}
.module_passing_content__qa_link_wrapper,
.module-video-content__qa_link_wrapper {
  text-align: center;
  margin: 50px 5px;
}
.module_passing_content__qa_link_wrapper a,
.module-video-content__qa_link_wrapper a {
  color: #9194a1;
}
@media (max-width: 760px) {
  .module_passing_content .MathJax,
  .module-video-content .MathJax {
    font-size: 1em;
  }
}
.module_passing_title {
  margin-top: 25px;
}
.module_passing_title h1 {
  line-height: normal;
  font-size: 36px;
  color: #232943;
  font-weight: 700;
  padding: 0;
  margin: 0 0 30px;
}
.module_teacher_info {
  margin-top: 40px;
  font-size: 0;
  cursor: pointer;
}
.module_teacher_info__photo {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 11px;
  display: inline-block;
  overflow: hidden;
}
.module_teacher_info__photo img {
  height: 36px;
  margin-left: -4px;
}
.module_teacher_info__name {
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #4B8CEE;
  padding-top: 6px;
  vertical-align: top;
}
.module_passing_content_inner,
.module-video-content__inner {
  line-height: 28px;
  color: #232943;
  font-size: 16px;
}
.module_passing_content_inner-w-full,
.module-video-content__inner-w-full {
  min-width: 720px;
}
@media (max-width: 1200px) {
  .module_passing_content_inner-w-full,
  .module-video-content__inner-w-full {
    min-width: 680px;
  }
}
@media (max-width: 760px) {
  .module_passing_content_inner-w-full,
  .module-video-content__inner-w-full {
    min-width: 280px;
  }
}
.module_passing_content_inner__video,
.module-video-content__inner__video,
.module_passing_content_inner__video_explanation,
.module-video-content__inner__video_explanation,
.module_passing_content_inner-video,
.module-video-content__inner-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 10px;
  margin-bottom: 35px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}
.module_passing_content_inner__video [data-video],
.module-video-content__inner__video [data-video],
.module_passing_content_inner__video_explanation [data-video],
.module-video-content__inner__video_explanation [data-video],
.module_passing_content_inner-video [data-video],
.module-video-content__inner-video [data-video],
.module_passing_content_inner__video iframe,
.module-video-content__inner__video iframe,
.module_passing_content_inner__video_explanation iframe,
.module-video-content__inner__video_explanation iframe,
.module_passing_content_inner-video iframe,
.module-video-content__inner-video iframe,
.module_passing_content_inner__video video,
.module-video-content__inner__video video,
.module_passing_content_inner__video_explanation video,
.module-video-content__inner__video_explanation video,
.module_passing_content_inner-video video,
.module-video-content__inner-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.module_passing_content_inner__video_explanation,
.module-video-content__inner__video_explanation {
  margin-top: 50px;
  width: 100%;
}
.module_passing_content_inner__description img,
.module-video-content__inner__description img,
.module_passing_content_inner-description img,
.module-video-content__inner-description img {
  max-width: 100%;
}
.module_passing_content_inner__description img:first-child:last-child,
.module-video-content__inner__description img:first-child:last-child,
.module_passing_content_inner-description img:first-child:last-child,
.module-video-content__inner-description img:first-child:last-child {
  margin: 50px auto;
  display: block;
}
.module_passing_content_inner__description [data-video],
.module-video-content__inner__description [data-video],
.module_passing_content_inner-description [data-video],
.module-video-content__inner-description [data-video],
.module_passing_content_inner__description iframe,
.module-video-content__inner__description iframe,
.module_passing_content_inner-description iframe,
.module-video-content__inner-description iframe,
.module_passing_content_inner__description video,
.module-video-content__inner__description video,
.module_passing_content_inner-description video,
.module-video-content__inner-description video {
  min-height: 406px;
}
@media (max-width: 760px) {
  .module_passing_content_inner__description [data-video],
  .module-video-content__inner__description [data-video],
  .module_passing_content_inner-description [data-video],
  .module-video-content__inner-description [data-video],
  .module_passing_content_inner__description iframe,
  .module-video-content__inner__description iframe,
  .module_passing_content_inner-description iframe,
  .module-video-content__inner-description iframe,
  .module_passing_content_inner__description video,
  .module-video-content__inner__description video,
  .module_passing_content_inner-description video,
  .module-video-content__inner-description video {
    min-height: 212px;
  }
}
.module_passing_content_inner__description--inline,
.module-video-content__inner__description--inline,
.module_passing_content_inner-description--inline,
.module-video-content__inner-description--inline {
  line-height: 40px;
}
.module_passing_content_inner__description table,
.module-video-content__inner__description table,
.module_passing_content_inner-description table,
.module-video-content__inner-description table {
  min-width: 60%;
}
.module_page {
  position: relative;
}
.module_page__wrapper_content {
  border-radius: 16px 16px;
  background: #FFFFFF;
  /* overflow: hidden; */
}
.module_page__wrapper_content .task__composite_input > .select-dropdown-answer {
  margin-bottom: 20px;
}
.module_page__to_prev {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/next_task_ico.svg) 50% no-repeat;
  position: fixed;
  top: 50%;
  left: 20px;
  width: 60px;
  height: 60px;
  transform: scaleX(-1);
  cursor: pointer;
  margin-top: -30px;
}
.module_page__to_next {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/next_task_ico.svg) 50% no-repeat;
  position: fixed;
  top: 50%;
  right: 20px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-top: -30px;
}
@media (max-width: 760px) {
  .module_page__to_prev {
    position: fixed;
    top: auto;
    bottom: 16px;
    left: 16px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14);
    width: 48px;
    height: 48px;
    background-size: 36px;
  }
  .module_page__to_next {
    position: fixed;
    top: auto;
    bottom: 16px;
    right: 16px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14);
    width: 48px;
    height: 48px;
    background-size: 36px;
  }
}
.module_navigation {
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 0;
  margin: 0 auto;
  padding: 0;
  z-index: 100;
}
.module_navigation__wrapper {
  overflow: hidden;
  transition: height 0.25s 0.1s ease-in-out;
  box-sizing: border-box;
}
.module_navigation__group {
  display: inline-block;
  margin: 5px;
  border-radius: 25px;
}
.module_navigation__group > div {
  margin: 7px 5px;
}
.module_navigation_item {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  border-radius: 50%;
  padding: 10px 0;
  margin: 5px 5px;
  box-sizing: border-box;
  line-height: 16px;
  font-weight: 500;
}
.module_navigation_item--introduction {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/navigation_list.svg) 50% 50% no-repeat;
}
.module_navigation_item--task {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/navigation_play.svg) 50% 50% no-repeat;
}
.module_navigation_item--contest {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/navigation_pencil.svg) 50% 45% no-repeat;
}
.module_navigation_item--self_control {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/navigation_star.svg) 50% 50% no-repeat;
}
.module_navigation_item--active {
  position: relative;
  margin: 0;
  padding: 11px 0;
}
.module_navigation_item--active:after {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  top: -4px;
  left: -4px;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
.module_navigation_item--passed {
  background-color: #5BC36C;
}
.module_navigation_item--passed:hover {
  background-color: #6BCF7B;
}
.module_navigation_item--right {
  background: #5BC36C url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/navigation_checkmark.svg) 60% 60% no-repeat;
}
.module_navigation_item--right:hover {
  background-color: #6BCF7B;
}
.module_navigation_item--partially_right {
  background: #FFD000 url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/navigation_checkmark.svg) 60% 60% no-repeat;
}
.module_navigation_item--partially_right:hover {
  background-color: #E5E000;
}
.module_navigation_item--not_right {
  background: #FF5E73 url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/navigation_checkmark.svg) 60% 60% no-repeat;
  color: #fff;
}
.module_navigation_item--not_right:hover {
  background-color: #FF4467;
}
.hint_page__content {
  max-width: 720px;
  margin: 64px auto;
}
@media (max-width: 760px) {
  .hint_page__content {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
}
.hint_page__item {
  margin-bottom: 50px;
}
.hint_page__more_button {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 28px;
  width: 178px;
  height: 36px;
  margin: 0 auto 20px auto;
  padding-top: 9px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  color: #828282;
}
.hint_page__to_contest_button {
  background: linear-gradient(261.86deg, #40CCFB 0%, #4B8CEE 100%), #4B8CEE;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 28px;
  width: 111px;
  height: 36px;
  margin: 0 auto;
  padding-top: 9px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
}
.button_answer {
  margin: 20px auto;
  height: 56px;
  width: 180px;
  padding-top: 19px;
  box-sizing: border-box;
  background: #D6DEE8;
  border-radius: 28px;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  transition: all 0.6s;
}
.button_answer--ready,
.button_answer--complete {
  background: #4B8CEE;
  box-shadow: 0 4px 8px rgba(35, 41, 67, 0.25);
}
.button_answer--has_no_tries {
  width: 328px;
  border-radius: 8px;
  margin-top: 0;
}
@media (max-width: 760px) {
  .button_answer--has_no_tries {
    width: 100%;
  }
}
.video_explanation {
  background: #F2F2F2;
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  padding-top: 50px;
}
.video_explanation__close_button {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/close_cross_black.svg) 50% 50% no-repeat;
  stroke: black;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 22px;
  right: 13px;
}
.video_explanation__content_inner_video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin-top: 10px;
  border-radius: 16px;
  overflow: hidden;
}
.video_explanation__content_inner_video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.notifications_list {
  background: #FBF9F3;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  font-size: 0;
  position: fixed;
  left: 16px;
  top: 72px;
  width: 360px;
  height: calc(100% - 98px);
  z-index: 100;
}
@media (max-width: 760px) {
  .notifications_list {
    border-radius: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 600ms;
  }
}
.notifications_list__content {
  width: 100%;
  height: calc(100% - 56px);
}
@media (max-width: 760px) {
  .notifications_list__content {
    padding-bottom: 136px;
    box-sizing: border-box;
  }
}
.notifications_list__header {
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 0px;
  height: 56px;
  width: 100%;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 14px;
  box-sizing: border-box;
  font-size: 0;
  position: relative;
  z-index: 999;
}
@media (max-width: 760px) {
  .notifications_list__header {
    border-radius: 0;
  }
}
.notifications_list__header > div {
  display: inline-block;
  vertical-align: top;
}
.notifications_list__header_widgets {
  position: absolute;
  right: 14px;
  top: 0;
}
.notifications_list__back_button {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cross.svg) 50% 50% no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
@media (max-width: 760px) {
  .notifications_list__back_button {
    background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/auth_back_arrow.svg) 50% 50% no-repeat;
  }
}
.notifications_list__title {
  line-height: 24px;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #232943;
  margin-left: 32px;
}
.notifications_list__pinned_list {
  position: fixed;
  width: 360px;
  z-index: 2;
}
@media (max-width: 760px) {
  .notifications_list__pinned_list {
    width: 100%;
  }
}
.notifications_list__list {
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.notifications_list_item {
  margin: 20px 16px;
  position: relative;
  padding-left: 48px;
}
.notifications_list_item__teacher_photo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.notifications_list_item__teacher_photo img {
  height: 40px;
}
.notifications_list_item__content {
  background: #FFFFFF;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 4px;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #000000;
  padding: 8px 12px;
  box-sizing: border-box;
}
.notifications_list_item__content a {
  text-decoration: none;
  color: #4B8CEE;
}
.notifications_list_item__teacher_name {
  color: #9194A1;
  line-height: 22px;
}
.notifications_list_item__close {
  width: 24px;
  height: 24px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cross.svg) 50% 50% no-repeat;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.notifications_list_item__link {
  text-decoration: none;
  color: #4b8cee;
  cursor: pointer;
}
.notifications_list_item--pinned {
  background: #FFFFFF;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  z-index: 2;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}
.notifications_list_item--pinned .notifications_list_item__content {
  box-shadow: none;
  padding: 18px 28px;
}
.notifications_list_item--pinned .notifications_list_item__teacher_photo {
  bottom: 20px;
  left: 16px;
}
.notifications_list_item--new:not(.notifications_list_item--pinned) {
  margin: 22px 18px;
  padding-left: 46px;
}
.notifications_list_item--new:not(.notifications_list_item--pinned) .notifications_list_item__content {
  position: relative;
}
.notifications_list_item--new:not(.notifications_list_item--pinned) .notifications_list_item__content:before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  border-radius: 18px 18px 18px 5px;
  background: linear-gradient(254.27deg, #40CCFB 0%, #4B8CEE 100%), #4B8CEE;
}
.notifications_list_item--link {
  cursor: pointer;
}
.notification_page {
  min-height: 100vh;
  position: relative;
  padding-bottom: 132px;
  box-sizing: border-box;
}
.notification_page__wrapper {
  margin: 0 auto;
  max-width: 600px;
  padding-top: 100px;
  padding-bottom: 294px;
}
@media (max-width: 760px) {
  .notification_page__wrapper {
    padding-top: 64px;
  }
}
.notification_page__title {
  font-style: normal;
  font-weight: normal;
  line-height: 44px;
  font-size: 36px;
  letter-spacing: -0.01em;
  color: #232943;
}
.notification_page__time {
  line-height: 18px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #9194A1;
  margin-top: 12px;
}
.notification_page__notification_text {
  line-height: 30px;
  font-size: 20px;
  letter-spacing: -0.01em;
  color: #232943;
  margin-top: 19px;
}
.notification_page__content {
  margin-top: 40px;
  line-height: 28px;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #232943;
}
.notification_page__content a {
  text-decoration: none;
  color: #4B8CEE;
}
.notification_page__content img:not([width]) {
  max-width: 100%;
}
@media (max-width: 760px) {
  .notification_page__wrapper {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
}
.notification_page .landings_footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.notifications_icon {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/notfifications-bell.svg) left no-repeat;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 22px;
  margin-top: 17.5px;
  padding-left: 10px;
  padding-right: 5px;
  width: auto;
  cursor: pointer;
  min-width: 5px;
  z-index: 1;
}
.notifications_icon__wrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.notifications_icon__numbers {
  background: #2F80ED;
  height: 14px;
  font-size: 11px;
  color: #FFFFFF;
  text-align-all: center;
  padding: 0 4px;
  border-radius: 4px;
  margin: 5px -4px 0 0;
}
.notifications_icon__numbers--has_new {
  background: #FF5E73;
}
.notifications_icon--transparent {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/notifications_bell_transparent.svg) left no-repeat;
}
.qa_page__content {
  max-width: 720px;
  padding: 106px 16px 40px 16px;
  margin: 0 auto;
}
.qa_page__not_found_wrapper {
  margin: 52px auto 52px auto;
  width: 278px;
  font-size: 0;
}
.qa_page__not_found_wrapper .qa_page__to_contest_button {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.qa_page__not_found_button {
  display: inline-block;
  vertical-align: middle;
  width: 146px;
  height: 36px;
  padding-top: 9px;
  box-sizing: border-box;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 28px;
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  color: #828282;
}
.qa_page__not_found_button--disabled {
  background: #E0E0E0;
}
.qa_page__not_found_block {
  margin: 24px auto;
  font-size: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #36363E;
}
.qa_page__prof {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_advising.svg) no-repeat;
  width: 56px;
  height: 65px;
}
.qa_page__advice_text {
  width: 100%;
  margin: 10px auto 20px auto;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 4px;
  padding: 8px 12px 10px 12px;
  box-sizing: border-box;
}
.qa_page__advice_text h3 {
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.01em;
  color: #9194A1;
  padding: 0;
  margin: 0;
}
.qa_page__advice_text p {
  line-height: 18px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #000000;
  padding: 0;
  margin: 0;
}
a.qa_page__to_contest_button,
a.qa_page__ask_button,
span.qa_page__ask_button {
  height: 36px;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  padding-top: 9px;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  background: linear-gradient(245.31deg, #40CCFB 48.72%, #4B8CEE 98.18%), #56CCF2;
  background-size: 200%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 28px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s, background 0.1s;
}
a.qa_page__to_contest_button:hover,
a.qa_page__ask_button:hover,
span.qa_page__ask_button:hover {
  background-position: 100% 50%;
}
a.qa_page__to_contest_button:active,
a.qa_page__ask_button:active,
span.qa_page__ask_button:active {
  background: #4B8EEE;
}
a.qa_page__to_contest_button--disabled,
a.qa_page__ask_button--disabled,
span.qa_page__ask_button--disabled {
  background: #E0E0E0;
}
a.qa_page__to_contest_button--disabled:active,
a.qa_page__ask_button--disabled:active,
span.qa_page__ask_button--disabled:active {
  background: #E0E0E0;
}
.qa_page__to_contest_button {
  margin-right: 11px;
}
.qa_page__wrapper {
  margin-top: 76px;
}
@media (max-width: 760px) {
  .qa_page__wrapper {
    margin-top: 37px;
  }
}
.qa_item {
  margin-bottom: 54px;
}
.qa_item__question_block {
  line-height: 24px;
  font-size: 18px;
  letter-spacing: -0.01em;
  color: #000000;
  font-weight: 500;
}
.qa_item__student_name,
.qa_item__teacher_name {
  font-size: 15px;
  font-weight: normal;
}
.qa_item__answer_block {
  line-height: 22px;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #828282;
}
.send_question_page__content {
  max-width: 720px;
  padding: 52px 16px 40px 16px;
  margin: 0 auto;
}
.send_question_page__content--submitted {
  max-width: 328px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -164px;
  margin-top: -200px;
}
.send_question_page__info {
  margin: 24px auto;
  max-width: 328px;
  font-size: 0;
}
.send_question_page__prof {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_advising.svg) no-repeat;
  width: 56px;
  height: 65px;
}
.send_question_page__info_text {
  width: 100%;
  margin: 10px auto 20px auto;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 4px;
  padding: 8px 12px 10px 12px;
  box-sizing: border-box;
}
.send_question_page__info_text h3 {
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.01em;
  color: #9194A1;
  padding: 0;
  margin: 0;
}
.send_question_page__info_text p {
  line-height: 18px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #000000;
  padding: 0;
  margin: 0;
}
.send_question_page__form {
  position: relative;
}
.send_question_page__form_textarea {
  width: 100%;
  height: 335px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  line-height: 24px;
  font-size: 18px;
  letter-spacing: -0.01em;
  color: #000000;
  padding: 13px 42px 13px 18px;
  outline: none;
  box-sizing: border-box;
  resize: none;
  font-family: inherit;
}
.send_question_page__form_textarea::-webkit-input-placeholder,
.send_question_page__form_textarea:-moz-placeholder,
.send_question_page__form_textarea::-moz-placeholder,
.send_question_page__form_textarea:-ms-input-placeholder {
  color: #828282;
  line-height: 20px;
}
.send_question_page__form_submit {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  bottom: 6px;
  border: none;
  outline: none;
  background: none;
}
.send_question_page__form_submit:after {
  content: "";
  position: absolute;
  top: 9px;
  right: 6px;
  width: 24px;
  height: 24px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/plane_gray.svg) 50% 50% no-repeat;
}
.send_question_page__form_submit--allowed {
  background: linear-gradient(266.78deg, #00D1FF 0%, #0067FF 100%), #2F80ED;
  cursor: pointer;
}
.send_question_page__form_submit--allowed:after {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/plane.svg) 50% 50% no-repeat;
}
a.send_question_page__back_button {
  display: block;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(261.79deg, #40CCFB 0%, #4B8CEE 100%), #4B8CEE;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  height: 36px;
  width: 112px;
  padding-top: 9px;
  margin-top: 20px;
  cursor: pointer;
  box-sizing: border-box;
}
.course_landing_page {
  position: relative;
  min-height: 100vh;
}
.course_landing_page a {
  text-decoration: none;
}
.course_landing_page__about_description img:not([width]) {
  width: 100%;
}
.course_landing_page__about-description {
  max-width: 320px;
  display: inline-block;
  vertical-align: top;
}
.course_landing_page__about-description-image-true img:not([width]) {
  width: 100%;
}
div .course_landing_page__about-description-image-false {
  max-width: inherit;
  width: 100%;
}
@media (max-width: 1200px) {
  .course_landing_page__about-description {
    max-width: none;
  }
}
.course_landing_page__block_wrapper {
  max-width: 830px;
  margin: 0 auto;
  padding: 0 40px;
}
@media (max-width: 760px) {
  .course_landing_page__block_wrapper {
    width: 100%;
    padding: 0;
  }
}
.course_landing_page__header {
  position: relative;
  color: #FFFFFF;
  box-sizing: border-box;
  padding-bottom: 138px;
  overflow: hidden;
  background: #6C4DC4;
}
@media (max-width: 760px) {
  .course_landing_page__header {
    min-height: 100vh;
  }
}
.course_landing_page__header_bg {
  position: absolute;
  top: 0;
  left: 0;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/course_landing_top_bg.svg) no-repeat;
  mix-blend-mode: multiply;
  background-size: cover;
  width: 100vw;
  height: 100%;
}
@media (max-width: 760px) {
  .course_landing_page__header_bg {
    height: 100%;
  }
}
.course_landing_page__header_content {
  position: relative;
  top: 0;
  height: inherit;
  width: inherit;
  padding-top: 110px;
  box-sizing: border-box;
  z-index: 1;
}
@media (max-width: 760px) {
  .course_landing_page__header_content {
    padding-top: 130px;
  }
}
.course_landing_page__header a {
  color: #56CCF2;
  text-decoration: none;
}
.course_landing_page__header_buttons_wrapper {
  height: 56px;
}
@media (max-width: 760px) {
  .course_landing_page__header_buttons_wrapper {
    height: auto;
  }
}
.course_landing_page__header_parents_button {
  position: relative;
  height: 32px;
  width: 236px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 28px;
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  color: #56CCF2;
  padding-top: 7px;
  box-sizing: border-box;
  background: #6C4DC4;
  cursor: pointer;
}
@media (max-width: 760px) {
  .course_landing_page__header_parents_button {
    display: block;
    margin: 30px auto 0 auto;
  }
}
.course_landing_page__header_parents_button:after {
  content: "";
  position: absolute;
  width: 240px;
  height: 36px;
  border-radius: 28px;
  top: -2px;
  left: -2px;
  background: linear-gradient(258.76deg, #40CCFB 0%, #4B8CEE 100%), #4B8CEE;
  z-index: -1;
}
.course_landing_page__sirius_title {
  line-height: 20px;
  font-size: 14px;
  margin-left: 56px;
  width: 154px;
}
.course_landing_page__course_title {
  font-style: normal;
  font-weight: normal;
  line-height: 48px;
  font-size: 48px;
  letter-spacing: -0.02em;
  margin-top: 36px;
  max-width: 745px;
  margin-bottom: 20px;
}
@media (max-width: 760px) {
  .course_landing_page__course_title {
    line-height: 30px;
    font-size: 32px;
    margin: 27px 36px 46px 56px;
  }
}
.course_landing_page__course_description {
  line-height: 30px;
  font-size: 18px;
  max-width: 640px;
  margin-bottom: 60px;
}
@media (max-width: 760px) {
  .course_landing_page__course_description {
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 56px;
  }
  .course_landing_page__course_description p {
    margin-left: 0;
  }
}
.course_landing_page__to_course_button_arrow {
  display: none;
}
.course_landing_page__to_course_button {
  font-family: inherit;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  color: #FFFFFF;
  box-shadow: 0 8px 16px rgba(84, 120, 8, 0.15);
  border: none;
  border-radius: 12px;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  height: 56px;
  width: 260px;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.4s;
  overflow: hidden;
}
.course_landing_page__to_course_button:hover {
  background: #55AB00;
}
.course_landing_page__to_course_button-cluster-cpm,
.course_landing_page__to_course_button-cluster-sirius,
.course_landing_page__to_course_button-cluster-textbook,
.course_landing_page__to_course_button-cluster-corp {
  background-color: #88CC00;
}
.course_landing_page__to_course_button-cluster-univ {
  background-color: #0DB1AA;
}
.course_landing_page__to_course_button-cluster-univ:hover {
  background-color: #3E878A;
}
.course_landing_page__to_course_button:focus {
  border: 2px solid rgba(0, 0, 0, 0.16);
}
.course_landing_page__to_course_button:active {
  background: #499100;
}
.course_landing_page__to_course_button[disabled] {
  border: 1px solid #E1DFE6;
  color: #C6C2CC;
  background: transparent;
  box-shadow: none;
  font-weight: normal;
}
.course_landing_page__to_course_button[disabled] svg path {
  fill: #C6C2CC;
}
@media (max-width: 760px) {
  .course_landing_page__to_course_button {
    width: 240px;
    margin: 85px auto 0 56px;
    display: block;
  }
}
.course_landing_page__to_course_button_bottom {
  padding-right: 32px;
}
.course_landing_page__to_course_button_bottom .course_landing_page__to_course_button_arrow {
  left: auto;
  right: 16px;
}
@media (max-width: 760px) {
  .course_landing_page__to_course_button_bottom {
    margin-top: 20px;
  }
}
.course_landing_page__to_course_button--middle {
  width: 56px;
  position: fixed;
  left: 100%;
  margin-left: -80px;
  top: 100%;
  margin-top: -136px;
  border-radius: 50%;
}
.course_landing_page__to_course_button--middle .course_landing_page__to_course_button_arrow {
  left: 16px;
  display: block;
}
.course_landing_page__to_course_button--bottom {
  position: fixed;
  top: 100%;
  left: 50%;
  margin-top: -296px;
  padding-left: 0;
  padding-right: 20px;
  margin-left: -120px;
}
.course_landing_page__to_course_button--bottom .course_landing_page__to_course_button_arrow {
  left: auto;
  right: 16px;
}
.course_landing_page__to_course_button_arrow {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 22px;
  top: 16px;
}
.course_landing_page__to_course_button--small {
  width: 56px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  margin: 0;
}
.course_landing_page__to_course_button--small .course_landing_page__to_course_button_arrow {
  left: 20px;
}
.course_landing_page__locked_text {
  display: inline-block;
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  margin-left: 35px;
}
@media (max-width: 760px) {
  .course_landing_page__locked_text {
    display: block;
    margin: 20px auto 20px 56px;
  }
}
.course_landing_page__content {
  background: #FCFCF9;
  border-radius: 0 0 16px 16px;
  line-height: 22px;
  font-size: 16px;
  padding-bottom: 236px;
  box-sizing: border-box;
}
@media (max-width: 760px) {
  .course_landing_page__content {
    padding-bottom: 428px;
  }
}
.course_landing_page__lecturers_explanation {
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
}
@media (max-width: 760px) {
  .course_landing_page__lecturers_explanation {
    line-height: 34px;
    font-size: 30px;
  }
}
.course_landing_page__lecturers_list {
  padding-top: 76px;
  font-size: 0;
}
@media (max-width: 760px) {
  .course_landing_page__lecturers_list {
    padding-top: 38px;
  }
}
.course_landing_page__lecturers_list_item {
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
  margin-bottom: 12px;
}
.course_landing_page__lecturers_list_item--hidden {
  display: none;
}
.course_landing_page__lecturers_list_item_img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 760px) {
  .course_landing_page__lecturers_list_item_img {
    width: 40px;
    height: 40px;
  }
}
.course_landing_page__lecturers_list_item img {
  width: 100%;
}
.course_landing_page__lecturers_list_item_name {
  margin-top: 18px;
  line-height: 22px;
  font-size: 12px;
  opacity: 0.5;
  width: 148px;
}
@media (max-width: 760px) {
  .course_landing_page__lecturers_list_item_name {
    display: none;
  }
}
.course_landing_page__about {
  padding: 56px 0 105px 0;
}
@media (max-width: 760px) {
  .course_landing_page__about {
    padding-bottom: 90px;
  }
}
.course_landing_page__about img {
  margin-right: 196px;
  max-width: 320px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 760px) {
  .course_landing_page__about img {
    display: block;
    margin: 0 auto;
  }
}
.course_landing_page__about_explanation {
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 50px;
}
@media (max-width: 760px) {
  .course_landing_page__about_explanation {
    line-height: 34px;
    font-size: 30px;
  }
}
.course_landing_page__about_description {
  max-width: 320px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 760px) {
  .course_landing_page__about_description {
    max-width: none;
  }
}
.course_landing_page__program {
  padding-top: 100px;
}
@media (max-width: 760px) {
  .course_landing_page__program {
    padding-top: 90px;
  }
}
.course_landing_page__program_explanation {
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
}
@media (max-width: 760px) {
  .course_landing_page__program_explanation {
    line-height: 34px;
    font-size: 30px;
  }
}
.course_landing_page__program_composition {
  font-size: 0;
}
@media (max-width: 760px) {
  .course_landing_page__program_composition {
    width: calc(100% - 64px);
  }
}
.course_landing_page__program_composition_item {
  display: inline-block;
  vertical-align: top;
  width: 144px;
  margin-right: 26px;
}
@media (max-width: 760px) {
  .course_landing_page__program_composition_item {
    margin-bottom: 35px;
    width: auto;
    margin-right: 33px;
    padding-right: 20px;
  }
}
.course_landing_page__program_composition_item_logo {
  width: 24px;
  height: 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-bottom: 15px;
}
.course_landing_page__program_composition_item_logo_summary {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/clock_icon.svg);
}
.course_landing_page__program_composition_item_logo_certificate {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/certificate_icon.svg);
}
.course_landing_page__program_composition_item_logo_progresses {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/square_cap_icon.svg);
}
.course_landing_page__program_composition_item_counts {
  line-height: 18px;
  font-size: 12px;
  letter-spacing: 0.02em;
}
.course_landing_page__program_composition_item_counts a {
  color: #2F80ED;
  text-decoration: none;
}
.course_landing_page__program_menu {
  width: 100%;
  margin-top: 60px;
}
@media (max-width: 760px) {
  .course_landing_page__program_menu {
    margin-top: 44px;
  }
}
.course_landing_page__program_menu_wrapper {
  font-size: 0;
  max-width: 830px;
  margin: 0 auto;
}
@media (max-width: 760px) {
  .course_landing_page__program_menu_wrapper {
    margin-left: 32px;
    margin-right: 32px;
    width: calc(100% - 64px);
  }
}
.course_landing_page__program_menu_item {
  display: inline-block;
  height: 56px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.02em;
  opacity: 0.5;
  box-sizing: border-box;
  cursor: pointer;
  padding: 16px 29px 0 29px;
}
.course_landing_page__program_menu_item--active {
  opacity: 1;
  border-bottom: 2px solid #E0E0E0;
}
.course_landing_page__themes {
  background: #FFFFFF;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.25);
}
.course_landing_page__themes_list {
  max-width: 830px;
  margin: 0 auto;
  padding: 0;
}
.course_landing_page__themes_list_item {
  height: 80px;
  padding-top: 15px;
  padding-left: 16px;
  border-top: 1px solid #F2F2F2;
  box-sizing: border-box;
  list-style: none;
  font-size: 0;
}
.course_landing_page__themes_list_item:first-child {
  border: none;
}
.course_landing_page__themes_list_item_text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 24px;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.02em;
}
.course_landing_page__themes_list_item_widget {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/progress-circle-empty.svg) 50% 50% no-repeat;
  width: 24px;
  height: 24px;
}
.course_landing_page__themes_list_item_widget_counter {
  position: absolute;
  top: 5px;
  left: 24px;
  height: 14px;
  padding-top: 2px;
  box-sizing: border-box;
  line-height: normal;
  font-size: 11px;
  text-align: center;
  color: #BDBDBD;
}
.course_landing_page_graph {
  width: 100%;
  height: 640px;
}
.course_landing_page__how_to {
  padding-top: 100px;
  padding-bottom: 103px;
}
@media (max-width: 760px) {
  .course_landing_page__how_to {
    padding-top: 55px;
    padding-bottom: 88px;
  }
}
.course_landing_page__how_to_explanation {
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 81px;
}
@media (max-width: 760px) {
  .course_landing_page__how_to_explanation {
    line-height: 34px;
    font-size: 30px;
  }
}
.course_landing_page__how_to_video_explanation {
  position: relative;
  width: 405px;
  height: 203px;
  margin-top: 80px;
  margin-bottom: 77px;
  border-radius: 16px;
  overflow: hidden;
}
.course_landing_page__how_to_video_explanation iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
}
@media (max-width: 760px) {
  .course_landing_page__how_to_video_explanation {
    width: 320px;
    height: 160px;
    margin: 40px auto;
  }
}
.course_landing_page__how_to_list {
  font-size: 0;
}
.course_landing_page__how_to_list_item {
  display: inline-block;
  vertical-align: top;
  max-width: 320px;
  margin-right: 105px;
  margin-bottom: 15px;
  color: #000000;
}
.course_landing_page__how_to_list_item:nth-child(2n) {
  margin-right: 0;
}
.course_landing_page__how_to_list_item_image {
  margin-bottom: 20px;
  height: 72px;
}
@media (max-width: 760px) {
  .course_landing_page__how_to_list_item_image {
    margin-left: 32px;
    height: auto;
  }
}
.course_landing_page__how_to_list_item_image_1 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module.svg) no-repeat;
  width: 72px;
  height: 72px;
}
.course_landing_page__how_to_list_item_image_2 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_starred.svg) no-repeat;
  width: 72px;
  height: 72px;
}
.course_landing_page__how_to_list_item_image_3 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_success.svg) 0 100% no-repeat;
  width: 32px;
  position: relative;
  height: 72px;
}
.course_landing_page__how_to_list_item_image_3:before {
  content: "";
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_editable_red.svg) no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  left: 22px;
  z-index: 1;
}
.course_landing_page__how_to_list_item_image_3:after {
  content: "";
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_editable_violet.svg) no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  left: 46px;
  z-index: 2;
}
.course_landing_page__how_to_list_item p {
  font-family: "PT Root UI", sans-serif;
  line-height: 22px;
  font-size: 16px;
}
.course_landing_page__how_to_list_item h3 {
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  line-height: 32px;
  font-size: 18px;
}
@media (max-width: 760px) {
  .course_landing_page__how_to_list_item {
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-bottom: 32px;
    max-width: none;
  }
  .course_landing_page__how_to_list_item:nth-child(2n) {
    margin-right: auto;
    margin-left: auto;
  }
}
.course_landing_page__certificate_link {
  display: inline-block;
  margin-right: 180px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 349px;
  width: 245px;
  vertical-align: top;
}
.course_landing_page__certificate_link img {
  width: inherit;
  height: inherit;
  box-shadow: 0 4px 16px rgba(106, 105, 140, 0.15);
}
@media (max-width: 760px) {
  .course_landing_page__certificate_link {
    margin: 0 0 42px 32px;
    width: 138px;
    height: 197px;
  }
}
.course_landing_page__certificate_explanation {
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
}
@media (max-width: 760px) {
  .course_landing_page__certificate_explanation {
    line-height: 34px;
    font-size: 30px;
  }
}
.course_landing_page__certificate_description {
  line-height: 22px;
  font-size: 16px;
  color: #000000;
  display: inline-block;
  vertical-align: top;
  max-width: 320px;
}
.course_landing_page__certificate_description a {
  text-decoration: none;
  color: #2F80ED;
}
@media (max-width: 760px) {
  .course_landing_page__certificate_description {
    display: block;
    margin: 0 auto;
    max-width: none;
  }
}
.course_landing_page__footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.course_landing_page__footer_top {
  background: #6C4DC4;
  box-shadow: 0 15px 10px -15px #111;
  z-index: 1;
  padding-top: 64px;
  padding-bottom: 24px;
  box-sizing: border-box;
  font-size: 0;
}
.course_landing_page__footer_bottom {
  background: #3F2688;
  height: 64px;
  z-index: -1;
  padding-top: 15px;
  box-sizing: border-box;
  text-align: center;
  color: #FFFFFF;
  line-height: 30px;
  font-size: 14px;
  letter-spacing: 0.02em;
}
@media (max-width: 760px) {
  .course_landing_page__footer_bottom {
    height: 120px;
  }
}
.course_landing_page__footer_title {
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 48px;
  font-size: 48px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-bottom: 52px;
}
@media (max-width: 760px) {
  .course_landing_page__footer_title {
    line-height: 30px;
    font-size: 32px;
    margin: 0 36px 52px 64px;
  }
}
.course_landing_page__footer_input {
  border: 1px solid #E9EFF7;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 28px;
  width: 240px;
  height: 56px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 21px;
  line-height: 25px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #69798C;
  outline: none;
}
@media (max-width: 760px) {
  .course_landing_page__footer_input {
    margin: 0 auto;
    display: block;
  }
}
.course_landing_page__footer_lyceum {
  margin: 132px auto 0 auto;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.course_landing_page__navigation {
  width: 100%;
  height: 56px;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0;
  z-index: 8;
}
@media (min-width: 760px) {
  .course_landing_page__navigation {
    background-color: transparent !important;
  }
}
@media (max-width: 760px) {
  .course_landing_page__navigation {
    position: fixed;
  }
}
.course_landing_page__navigation--fixed {
  position: fixed;
  bottom: auto;
  top: 0;
  background-color: inherit !important;
}
.course_landing_page__navigation--fixed .course_landing_page__navigation_inner_wrapper {
  margin: 0 66px;
  max-width: initial;
}
.course_landing_page__navigation--fixed .course_landing_page__navigation_inner_wrapper--registered {
  margin-right: 120px;
}
.course_landing_page__navigation_progress_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
}
@media (max-width: 760px) {
  .course_landing_page__navigation_progress_wrapper {
    top: 0;
    bottom: auto;
  }
}
.course_landing_page__navigation_progressbar {
  height: 2px;
  background: #56CCF2;
  width: 0;
}
.course_landing_page__navigation_wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}
.course_landing_page__navigation_wrapper::-webkit-scrollbar {
  width: 0;
}
.course_landing_page__navigation_wrapper::-webkit-scrollbar-button {
  width: 0;
}
.course_landing_page__navigation_inner_wrapper {
  white-space: nowrap;
  height: 56px;
  margin: 0 auto;
  max-width: 838px;
  overflow: auto;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  scrollbar-width: thin;
}
.course_landing_page__navigation_inner_wrapper::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.course_landing_page__navigation_inner_wrapper::-webkit-scrollbar-track {
  background: transparent;
}
.course_landing_page__navigation_inner_wrapper::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.course_landing_page__navigation_inner_wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}
.course_landing_page__navigation_inner_wrapper::-webkit-scrollbar {
  height: 8px;
  width: 5px;
}
@media (max-width: 760px) {
  .course_landing_page__navigation_inner_wrapper {
    width: auto;
  }
}
@media (max-width: 992px) {
  .course_landing_page__navigation_inner_wrapper {
    margin: 0 10px;
  }
}
.course_landing_page__navigation_item {
  z-index: 999;
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  padding-top: 16px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}
.course_landing_page__navigation_item a {
  display: block;
  text-decoration: none;
  color: inherit;
  height: inherit;
  opacity: 0.5;
}
.course_landing_page__navigation_item a.active {
  opacity: 1;
}
.course_landing_page__invitation {
  font-family: "PT Root UI", Arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media (max-width: 760px) {
  .course_landing_page__invitation {
    margin-left: 56px;
  }
}
.course_landing_page__refuse_button {
  height: 56px;
  background: rgba(255, 255, 255, 0.1);
  font-weight: 300;
  color: #FFFFFF;
  font-size: 18px;
  line-height: 22px;
  border: none;
  border-radius: 12px;
  transition: all 0.4s;
  padding: 0 38px;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
}
.course_landing_page__refuse_button:hover {
  background: rgba(255, 255, 255, 0.2);
}
@media (max-width: 760px) {
  .course_landing_page__refuse_button {
    display: block;
    margin: 20px auto 0 56px;
  }
}
.course_landing_page__section {
  width: 100%;
}
.landing_lecturer_card {
  position: fixed;
}
.platform_landing_page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #FCFCF9;
}
.platform_landing_page a {
  text-decoration: none;
}
.platform_landing_page .platform_landing_page__courses:first-child {
  padding-top: 40px;
}
.platform_landing_page__block_wrapper {
  max-width: 830px;
  margin: 0 auto;
  padding: 0 40px;
}
.platform_landing_page__block_wrapper--feedback_page {
  text-align: center;
}
.platform_landing_page__block_wrapper--feedback_page .feedback_page__title {
  margin: 0 auto 20px;
}
@media (max-width: 760px) {
  .platform_landing_page__block_wrapper {
    padding: 0 8px;
    width: calc(100% - 16px);
  }
}
.platform_landing_page__greeting {
  font-size: 0;
  padding: 20px 50px 0 50px;
  margin: 0 auto;
  max-width: 1680px;
}
.platform_landing_page__greeting--deploy-cpm .platform_landing_for_authorized_page__courses_cheops {
  max-width: none;
}
.platform_landing_page__greeting--deploy-cpm .lock_bar__prof_advice_text {
  background: none !important;
  box-shadow: none;
  border: none;
}
.platform_landing_page__greeting--deploy-cpm .lock_bar__prof_img {
  width: 138px;
  height: 200px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_greeting_cpm.svg) 50% / contain no-repeat;
  display: inline-block;
  margin-right: 55px;
  vertical-align: middle;
}
.platform_landing_page__greeting--deploy-cpm .lock_bar__prof_text {
  color: #36363E;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  vertical-align: middle;
  display: inline-block;
}
.platform_landing_page__greeting--deploy-cpm .lock_bar__prof_text h3 {
  display: none;
}
@media (max-width: 760px) {
  .platform_landing_page__greeting {
    padding: 20px 8px 0 8px;
  }
  .platform_landing_page__greeting--deploy-cpm .lock_bar__prof_img {
    margin-bottom: 36px;
  }
}
.platform_landing_page__header_cheops {
  margin-right: 31px;
  max-width: 344px;
  display: inline-block;
}
.platform_landing_page__header_cheops .lock_bar__prof_advice_text {
  background: #EFEEF5;
}
@media (max-width: 760px) {
  .platform_landing_page__header_cheops {
    margin-top: 16px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    width: calc(100% - 16px);
  }
  .platform_landing_page__header_cheops .lock_bar__prof_advice_text p,
  .platform_landing_page__header_cheops .lock_bar__prof_advice_text h3 {
    width: 100%;
    margin: 0;
  }
}
.platform_landing_page__courses_content_wrapper {
  font-size: 0 ;
}
.platform_landing_page__courses_list_content {
  flex-wrap: wrap;
}
.platform_landing_page__header {
  position: relative;
  color: #000000;
  box-sizing: border-box;
  padding-bottom: 112px;
  overflow: hidden;
  background-color: #FCFCF9;
}
@media (max-width: 760px) {
  .platform_landing_page__header {
    padding-bottom: 20px;
  }
}
.platform_landing_page__header_content {
  position: relative;
  top: 0;
  height: inherit;
  width: inherit;
  padding-top: 97px;
  box-sizing: border-box;
  z-index: 1;
}
.platform_landing_page__header a {
  color: #2F80ED;
  text-decoration: none;
}
@media (max-width: 760px) {
  .platform_landing_page__header_buttons_wrapper {
    flex-direction: column;
    padding: 16px;
  }
}
.platform_landing_page__header_parents_button {
  position: relative;
  height: 32px;
  width: 236px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  color: #2F80ED;
  padding-top: 7px;
  box-sizing: border-box;
  background: #FCFCF9;
  cursor: pointer;
}
.platform_landing_page__header_parents_button:after {
  content: "";
  position: absolute;
  width: 240px;
  height: 36px;
  border-radius: 28px;
  top: -2px;
  left: -2px;
  background: linear-gradient(258.76deg, #40CCFB 0%, #4B8CEE 100%), #4B8CEE;
  z-index: -1;
}
@media (max-width: 760px) {
  .platform_landing_page__header_parents_button {
    display: block;
    margin: 30px auto 0 auto;
  }
}
.platform_landing_page__sirius_title {
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
  max-width: 560px;
  margin-bottom: 29px;
}
@media (max-width: 760px) {
  .platform_landing_page__sirius_title {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 20px;
    line-height: 30px;
    font-size: 30px;
  }
}
.platform_landing_page__platform_description {
  line-height: 30px;
  font-size: 18px;
  max-width: 640px;
  margin-bottom: 40px;
}
@media (max-width: 760px) {
  .platform_landing_page__platform_description {
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 16px;
    margin-right: 16px;
  }
  .platform_landing_page__platform_description p {
    margin-left: 0;
  }
}
.platform_landing_page__platform_description a {
  text-decoration: underline;
}
.platform_landing_page__start_study_button {
  color: #FFFFFF;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  border: none;
  border-radius: 8px;
  height: 56px;
  width: 320px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.5s, background 0.1s;
  cursor: pointer;
}
.platform_landing_page__start_study_button:hover {
  background: #599900;
}
.platform_landing_page__start_study_button:active {
  background: #499100;
}
.platform_landing_page__start_study_button[focus] {
  border: 2px solid rgba(0, 0, 0, 0.16);
}
@media (max-width: 760px) {
  .platform_landing_page__start_study_button {
    display: block;
  }
}
.platform_landing_page__start_study_button_arrow {
  display: none;
}
.platform_landing_page__start_study_button_bottom {
  padding-right: 32px;
}
.platform_landing_page__start_study_button_bottom .platform_landing_page__start_study_button_arrow {
  display: none;
  left: auto;
  right: 16px;
}
@media (max-width: 760px) {
  .platform_landing_page__start_study_button_bottom {
    margin-top: 20px;
  }
}
.platform_landing_page__start_study_button--middle {
  width: 56px;
  position: fixed;
  left: 100%;
  margin-left: -80px;
  top: calc(100% - 136px);
  border-radius: 50%;
}
.platform_landing_page__start_study_button--middle .platform_landing_page__start_study_button_arrow {
  display: block;
  left: 16px;
}
@media (max-width: 760px) {
  .platform_landing_page__start_study_button--middle {
    margin-top: 0;
  }
}
.platform_landing_page__start_study_button--bottom {
  position: fixed;
  top: 100%;
  left: 50%;
  margin-top: -296px;
  padding-left: 0;
  padding-right: 20px;
  margin-left: -120px;
}
.platform_landing_page__start_study_button--bottom .platform_landing_page__start_study_button_arrow {
  display: none;
  left: auto;
  right: 16px;
}
.platform_landing_page__start_study_button_arrow {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/arrow_button.svg) 50% 50% no-repeat;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 22px;
  top: 16px;
}
.platform_landing_page__start_study_button--small {
  width: 56px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  margin: 0;
}
.platform_landing_page__start_study_button--small .platform_landing_page__start_study_button_arrow {
  left: 20px;
}
.platform_landing_page__start_study_button--cluster-cpm,
.platform_landing_page__start_study_button--cluster-sirius,
.platform_landing_page__start_study_button--cluster-textbook,
.platform_landing_page__start_study_button--cluster-corp {
  background-color: #88CC00;
}
.platform_landing_page__start_study_button--cluster-univ {
  background-color: #0DB1AA;
}
.platform_landing_page__start_study_button--cluster-univ:hover {
  background-color: #3E878A;
}
.platform_landing_page__content {
  background: #FCFCF9;
  border-radius: 0 0 16px 16px;
  line-height: 22px;
  font-size: 16px;
  padding-bottom: 104px;
  box-sizing: border-box;
}
.platform_landing_page__lecturers {
  padding-top: 100px;
}
.platform_landing_page__lecturers_explanation {
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
}
@media (max-width: 760px) {
  .platform_landing_page__lecturers_explanation {
    line-height: 34px;
    font-size: 30px;
    margin-left: 32px;
    margin-right: 16px;
  }
}
.platform_landing_page__lecturers_list {
  margin-top: 76px;
  margin-bottom: 55px;
  font-size: 0;
}
@media (max-width: 760px) {
  .platform_landing_page__lecturers_list {
    width: calc(100% - 64px);
    margin: 38px 32px 20px 32px;
  }
}
.platform_landing_page__lecturers_list_item {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 760px) {
  .platform_landing_page__lecturers_list_item {
    margin-bottom: 12px;
  }
}
.platform_landing_page__lecturers_list_item--hidden {
  display: none;
}
.platform_landing_page__lecturers_list_item_img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 760px) {
  .platform_landing_page__lecturers_list_item_img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
.platform_landing_page__lecturers_list_item img {
  width: 100%;
}
.platform_landing_page__lecturers_list_item_name {
  margin-top: 18px;
  line-height: 22px;
  font-size: 12px;
  opacity: 0.5;
  width: 160px;
}
@media (max-width: 760px) {
  .platform_landing_page__lecturers_list_item_name {
    display: none;
  }
}
.platform_landing_page__lecturers_link {
  color: #4B8CEE;
  text-decoration: none;
}
@media (max-width: 760px) {
  .platform_landing_page__lecturers_link {
    margin-left: 32px;
  }
}
.platform_landing_page__lyceum {
  padding: 102px 0 40px 0;
}
@media (max-width: 760px) {
  .platform_landing_page__lyceum {
    padding: 0 32px 20px 32px;
  }
}
.platform_landing_page__lyceum_title {
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 32px;
}
@media (max-width: 760px) {
  .platform_landing_page__lyceum_title {
    line-height: 34px;
    font-size: 30px;
  }
}
.platform_landing_page__lyceum_explanation {
  line-height: 22px;
  font-size: 16px;
}
.platform_landing_page__lyceum_explanation p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  width: 100%;
}
.platform_landing_page__lyceum_explanation p :first-child {
  margin-top: 0;
}
.platform_landing_page__lyceum_explanation a {
  text-decoration: none;
  color: #4B8CEE;
}
@media (min-width: 760px) {
  .platform_landing_page__lyceum_explanation {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 320px;
    -moz-column-width: 320px;
    column-width: 320px;
    -webkit-column-gap: 105px;
    -moz-column-gap: 105px;
    column-gap: 105px;
  }
  .platform_landing_page__lyceum_explanation p {
    width: 320px;
  }
}
.platform_landing_page__courses {
  font-size: 0;
  padding: 100px 40px 0 40px;
  margin: 0 auto;
  max-width: 1680px;
  box-sizing: border-box;
}
@media (max-width: 760px) {
  .platform_landing_page__courses {
    padding: 100px 8px 0 8px;
  }
}
.platform_landing_page__courses_explanation {
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  margin-bottom: 100px;
}
@media (max-width: 760px) {
  .platform_landing_page__courses_explanation {
    line-height: 34px;
    font-size: 30px;
    margin-bottom: 41px;
    margin-left: 32px;
    margin-right: 32px;
  }
}
.platform_landing_page__courses_content {
  width: 100%;
  position: relative;
  margin: 0 auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
@media (max-width: 760px) {
  .platform_landing_page__courses_content {
    overflow-y: auto;
  }
}
.platform_landing_page__courses_content::-webkit-scrollbar {
  width: 0;
}
@media (max-width: 760px) {
  .platform_landing_page__courses_content_wrapper {
    width: inherit;
    min-width: 0;
    margin-left: 0;
  }
}
@media (max-width: 760px) {
  .platform_landing_page__courses_content_wrapper .platform_landing_page_courses_list_item {
    width: 304px;
  }
}
.platform_landing_page__courses_title {
  font-size: 36px;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: 0.01em;
  margin: 0 10px 26px 10px;
}
@media (max-width: 760px) {
  .platform_landing_page__courses_title {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 21px;
    font-size: 24px;
    line-height: 30px;
  }
  .platform_landing_page__courses_title--non_authorized {
    margin-right: 16px;
    margin-left: 16px;
  }
}
.platform_landing_page__courses_title_my {
  letter-spacing: -0.02em;
  margin-bottom: 46px;
}
@media (max-width: 760px) {
  .platform_landing_page__courses_title_my {
    box-sizing: border-box;
    margin-bottom: 24px;
    display: block;
  }
}
.platform_landing_page__courses_list {
  margin: 0 -10px 54px -10px;
}
.platform_landing_page__courses_list_wrapper {
  flex-wrap: wrap;
}
@media (max-width: 760px) {
  .platform_landing_page__courses_available {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
.platform_landing_page__courses_available_all {
  display: block;
  line-height: 22px;
  font-size: 16px;
  color: #4B8CEE;
  text-decoration: none;
  margin: 57px auto 95px 0;
}
@media (max-width: 760px) {
  .platform_landing_page__courses_available_all {
    margin-left: 24px;
  }
}
.platform_landing_page__courses_all_link {
  display: block;
  margin-left: 32px;
  margin-top: 64px;
  line-height: 22px;
  font-size: 16px;
  color: #2F80ED;
  text-decoration: none;
}
.platform_landing_page__courses_my_courses,
.platform_landing_page__courses_closed_courses {
  width: 720px;
  display: block;
  font-size: 0;
}
@media (max-width: 760px) {
  .platform_landing_page__courses_my_courses,
  .platform_landing_page__courses_closed_courses {
    padding-left: 8px;
    padding-right: 8px;
    width: calc(100% - 16px);
    max-width: 360px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .platform_landing_page__courses_my_courses .platform_landing_page_courses_list_item,
  .platform_landing_page__courses_closed_courses .platform_landing_page_courses_list_item {
    max-width: 344px;
  }
}
@media (max-width: 760px) {
  .platform_landing_page__courses_closed_courses .platform_landing_page_courses_list_item {
    max-width: 304px;
  }
  .platform_landing_page__courses_closed_courses .platform_landing_page_courses_list_item__cheops {
    max-width: 304px;
  }
}
.platform_landing_page_graph {
  width: 100%;
  height: 640px;
}
.platform_landing_page__how_to {
  padding-top: 100px;
  padding-bottom: 103px;
}
@media (max-width: 760px) {
  .platform_landing_page__how_to {
    padding-top: 76px;
    padding-bottom: 88px;
  }
}
.platform_landing_page__how_to_explanation {
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 91px;
}
@media (max-width: 760px) {
  .platform_landing_page__how_to_explanation {
    line-height: 34px;
    font-size: 30px;
    margin-left: 32px;
    margin-right: 16px;
  }
}
.platform_landing_page__how_to_video_explanation {
  position: relative;
  width: 405px;
  height: 203px;
  margin-top: 80px;
  margin-bottom: 77px;
  border-radius: 16px;
  overflow: hidden;
}
@media (max-width: 760px) {
  .platform_landing_page__how_to_video_explanation {
    width: 320px;
    height: 160px;
    margin: 40px auto;
  }
}
.platform_landing_page__how_to_video_explanation iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
}
.platform_landing_page__how_to_list {
  font-size: 0;
}
.platform_landing_page__how_to_list_item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  color: #000000;
  margin-bottom: 50px;
  padding-right: 40px;
  box-sizing: border-box;
}
.platform_landing_page__how_to_list_item img {
  max-width: 320px;
  margin-bottom: 20px;
}
.platform_landing_page__how_to_list_item:nth-child(2n) {
  margin-right: 0;
}
.platform_landing_page__how_to_list_item_image {
  margin-bottom: 20px;
  height: 72px;
}
@media (max-width: 760px) {
  .platform_landing_page__how_to_list_item_image {
    margin-left: 32px;
    height: auto;
  }
}
.platform_landing_page__how_to_list_item_image_1 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module.svg) no-repeat;
  width: 72px;
  height: 72px;
}
.platform_landing_page__how_to_list_item_image_2 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_starred.svg) no-repeat;
  width: 72px;
  height: 72px;
}
.platform_landing_page__how_to_list_item_image_3 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_success.svg) 0 100% no-repeat;
  width: 32px;
  position: relative;
  height: 72px;
}
.platform_landing_page__how_to_list_item_image_3:before {
  content: "";
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_editable_red.svg) no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  left: 22px;
  z-index: 1;
}
.platform_landing_page__how_to_list_item_image_3:after {
  content: "";
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_editable_violet.svg) no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  left: 46px;
  z-index: 2;
}
.platform_landing_page__how_to_list_item_image_4 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_starred_green.svg) 0 100% no-repeat;
  width: 32px;
  height: 32px;
  position: relative;
}
.platform_landing_page__how_to_list_item_image_4:before {
  content: "";
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_starred_purple.svg) no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  left: 22px;
  z-index: 1;
}
.platform_landing_page__how_to_list_item_image_5 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_video.svg) no-repeat;
  width: 120px;
  height: 70px;
}
.platform_landing_page__how_to_list_item_image_6 {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/how_to_module_qa.svg) 0 100% no-repeat;
  width: 32px;
  height: 32px;
}
.platform_landing_page__how_to_list_item p {
  line-height: 22px;
  font-size: 16px;
}
.platform_landing_page__how_to_list_item h3 {
  line-height: 32px;
  font-size: 18px;
}
@media (max-width: 760px) {
  .platform_landing_page__how_to_list_item {
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-bottom: 32px;
    max-width: none;
    width: 100%;
  }
  .platform_landing_page__how_to_list_item:nth-child(2n) {
    margin-right: auto;
    margin-left: auto;
  }
  .platform_landing_page__how_to_list_item:last-child {
    margin-bottom: 0;
  }
}
.platform_landing_page__certificate_link {
  display: inline-block;
  margin-right: 180px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 349px;
  width: 245px;
  vertical-align: top;
}
.platform_landing_page__certificate_link img {
  width: inherit;
  height: inherit;
  box-shadow: 0 4px 16px rgba(106, 105, 140, 0.15);
}
@media (max-width: 760px) {
  .platform_landing_page__certificate_link {
    margin: 0 0 42px 32px;
    width: 138px;
    height: 197px;
  }
}
.platform_landing_page__certificate_explanation {
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
}
@media (max-width: 760px) {
  .platform_landing_page__certificate_explanation {
    line-height: 34px;
    font-size: 30px;
    margin-left: 32px;
    margin-right: 16px;
  }
}
.platform_landing_page__certificate_description {
  line-height: 22px;
  font-size: 16px;
  color: #000000;
  display: inline-block;
  vertical-align: top;
  max-width: 320px;
}
.platform_landing_page__certificate_description a {
  text-decoration: none;
  color: #2F80ED;
}
@media (max-width: 760px) {
  .platform_landing_page__certificate_description {
    display: block;
    margin: 0 auto;
    max-width: none;
  }
}
.platform_landing_page__footer {
  width: 100%;
}
.platform_landing_page__footer_top {
  background: #6C4DC4;
  box-shadow: 0 15px 10px -15px #111;
  z-index: 1;
  padding-top: 64px;
  padding-bottom: 24px;
  box-sizing: border-box;
  font-size: 0;
}
.platform_landing_page__footer_bottom {
  background: #3F2688;
  height: 64px;
  z-index: -1;
  padding-top: 15px;
  box-sizing: border-box;
  text-align: center;
  color: #FFFFFF;
  line-height: 30px;
  font-size: 14px;
  letter-spacing: 0.02em;
}
@media (max-width: 760px) {
  .platform_landing_page__footer_bottom {
    height: 120px;
  }
}
.platform_landing_page__footer_title {
  font-style: normal;
  font-weight: normal;
  line-height: 54px;
  font-size: 48px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-bottom: 52px;
}
@media (max-width: 760px) {
  .platform_landing_page__footer_title {
    line-height: 30px;
    font-size: 32px;
    margin: 0 36px 52px 64px;
  }
}
.platform_landing_page__footer_input {
  border: 1px solid #E9EFF7;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 28px;
  width: 240px;
  height: 56px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 21px;
  line-height: 25px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #69798C;
  outline: none;
}
@media (max-width: 760px) {
  .platform_landing_page__footer_input {
    margin: 0 auto;
    display: block;
  }
}
.platform_landing_page__footer_lyceum {
  margin: 132px auto 0 auto;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.platform_landing_page__navigation {
  width: 100%;
  height: 56px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #FCFCF9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 0;
  z-index: 8;
}
@media (max-width: 760px) {
  .platform_landing_page__navigation {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    position: fixed;
  }
}
.platform_landing_page__navigation--fixed {
  position: fixed;
  bottom: auto;
  top: 0;
}
.platform_landing_page__navigation_progress_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
}
@media (max-width: 760px) {
  .platform_landing_page__navigation_progress_wrapper {
    top: 0;
    bottom: auto;
  }
}
.platform_landing_page__navigation_progressbar {
  height: 2px;
  background: #56CCF2;
}
.platform_landing_page__navigation_wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}
.platform_landing_page__navigation_wrapper::-webkit-scrollbar {
  width: 0;
}
.platform_landing_page__navigation_wrapper::-webkit-scrollbar-button {
  width: 0;
}
.platform_landing_page__navigation_inner_wrapper {
  white-space: nowrap;
  height: 56px;
  margin: 0 auto;
  width: 830px;
}
@media (max-width: 1200px) and (min-width: 760px) {
  .platform_landing_page__navigation_inner_wrapper {
    padding-left: 55px;
  }
}
@media (max-width: 760px) {
  .platform_landing_page__navigation_inner_wrapper {
    width: auto;
  }
}
.platform_landing_page__navigation_item {
  z-index: 999;
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  color: #000000;
  height: 56px;
  padding-top: 16px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}
.platform_landing_page__navigation_item a {
  display: block;
  text-decoration: none;
  color: inherit;
  height: inherit;
  opacity: 0.5;
}
.platform_landing_page__navigation_item a.active {
  opacity: 1;
}
.platform_landing_page__columns_title {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 8px;
}
.platform_landing_page__columns_text {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.platform_landing_for_authorized_page-cluster-cpm {
  background-color: #6C4DC4;
}
.platform_landing_for_authorized_page-cluster-sirius {
  background-color: #6C4DC4;
}
.platform_landing_for_authorized_page-cluster-univ {
  background-color: #0DB1AA;
}
.platform_landing_for_authorized_page-cluster-corp {
  background-color: #90A0C4;
}
@media (max-width: 760px) {
  .platform_landing_for_authorized_page p,
  .platform_landing_for_authorized_page h1,
  .platform_landing_for_authorized_page h2,
  .platform_landing_for_authorized_page h3,
  .platform_landing_for_authorized_page h4,
  .platform_landing_for_authorized_page h5,
  .platform_landing_for_authorized_page h6 {
    margin-left: 32px;
    margin-right: 32px;
    width: calc(100% - 64px);
  }
}
.platform_landing_for_authorized_page__content {
  background: #FCFCF9;
  border-radius: 0 0 16px 16px;
  padding-bottom: 78px;
  box-sizing: border-box;
  min-height: calc(100vh - 132px);
}
@media (max-width: 760px) {
  .platform_landing_for_authorized_page__content {
    padding-bottom: 95px;
  }
}
.platform_landing_for_authorized_page__courses_cheops {
  max-width: 344px;
  font-size: 0;
  margin-top: 0;
  display: inline-block;
}
.platform_landing_for_authorized_page__courses_cheops .lock_bar__prof_advice_text {
  background: #EFEEF5;
}
.platform_landing_for_authorized_page__courses_cheops p {
  margin: 0;
}
.platform_landing_for_authorized_page__courses_cheops h3 {
  margin: 0;
}
@media (max-width: 760px) {
  .platform_landing_for_authorized_page__courses_cheops {
    display: block;
    margin-left: 8px;
    margin-right: auto;
    width: calc(100% - 32px);
  }
  .platform_landing_for_authorized_page__courses_cheops p {
    width: 100%;
  }
}
.platform_landing_for_authorized_page__courses_cheops--my_courses {
  display: inline-block;
  margin-right: 16px;
  margin-top: 0;
  margin-bottom: 45px;
}
@media (max-width: 760px) {
  .platform_landing_for_authorized_page__courses_cheops--my_courses {
    margin-left: 8px;
    margin-top: 67px;
    max-width: 328px;
    width: calc(100% - 16px);
  }
}
.platform_landing_for_authorized_page__courses_cheops_logo {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_advising.svg) no-repeat;
  width: 56px;
  height: 65px;
}
.platform_landing_for_authorized_page__courses_cheops_text {
  width: 100%;
  margin: 10px auto 20px auto;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 4px;
  padding: 8px 12px 10px 12px;
  box-sizing: border-box;
}
.platform_landing_for_authorized_page__courses_cheops_text_name {
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.01em;
  color: #9194A1;
  padding: 0;
  margin: 0;
}
.platform_landing_for_authorized_page__courses_cheops_text_content {
  line-height: 18px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #000000;
  padding: 0;
  margin: 0;
}
.platform_landing_for_authorized_page__courses_my_courses_title {
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  font-size: 44px;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
}
@media (max-width: 760px) {
  .platform_landing_for_authorized_page__courses_my_courses_title {
    line-height: 34px;
    font-size: 30px;
    margin-bottom: 16px;
    margin-left: 32px;
  }
}
.landing_lecturer_card {
  position: fixed;
}
.platform_landing_page_courses_list_item {
  display: inline-block;
  vertical-align: top;
  width: 344px;
  height: 188px;
  box-shadow: 0 2px 10px rgba(167, 179, 194, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  color: #FCFCF9;
}
.platform_landing_page_courses_list_item__wrapper {
  width: 100%;
  height: 100%;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/course_card_landing_bg.svg) no-repeat;
  padding: 20px 40px 28px 24px;
  box-sizing: border-box;
  border-radius: 8px ;
}
.platform_landing_page_courses_list_item--my_course,
.platform_landing_page_courses_list_item--closed {
  margin-bottom: 44px;
}
.platform_landing_page_courses_list_item--upcoming {
  background: #FFFFFF;
  color: #000000;
}
.platform_landing_page_courses_list_item--upcoming .platform_landing_page_courses_list_item__wrapper {
  background: none;
}
.platform_landing_page_courses_list_item--upcoming .platform_landing_page_courses_list_item__consist {
  color: #828282;
}
.platform_landing_page_courses_list_item__date {
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.02em;
  height: 16px;
}
.platform_landing_page_courses_list_item__not_published {
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #000000;
  background: #FFD000;
  border-radius: 2px;
  width: 105px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  margin-top: -4px;
  box-sizing: border-box;
}
.platform_landing_page_courses_list_item__title {
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 24px;
  letter-spacing: -0.02em;
  margin-top: 8px;
  margin-bottom: 19px;
  height: 81px;
}
.platform_landing_page_courses_list_item__consist {
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.02em;
}
.platform_landing_page_courses_list_item__continue_button {
  display: block;
  background: linear-gradient(258.76deg, #40CCFB 0%, #4B8CEE 100%), #4B8CEE;
  box-shadow: 0 4px 8px rgba(35, 41, 67, 0.25);
  line-height: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 28px;
  height: 56px;
  width: 260px;
  position: absolute;
  bottom: -28px;
  left: 50%;
  margin-left: -130px;
  padding-top: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.platform_landing_page_courses_list_item__continue_button_title {
  text-decoration: none;
  color: #FFFFFF;
}
.platform_landing_page_courses_list_item__continue_button_arrow {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/arrow_button.svg) 50% 50% no-repeat;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 22px;
  top: 16px;
}
.platform_landing_page_courses_list_item__widgets {
  position: absolute;
  top: 16px;
  right: 10px;
}
.platform_landing_page_courses_list_item__progress {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 11px;
  text-align: center;
  color: #FFFFFF;
  margin-right: 20px;
  cursor: pointer;
}
.platform_landing_page_courses_list_item__progress svg {
  margin-right: 4px;
  vertical-align: bottom;
}
.platform_landing_page_courses_list_item__progress:last-child {
  margin-right: 0;
}
.platform_landing_page_courses_list_item__cheops {
  display: inline-block;
  vertical-align: top;
  max-width: 344px;
  margin-right: 16px;
}
.platform_landing_page_courses_list_item__cheops .lock_bar__prof_advice_text,
.platform_landing_page_courses_list_item__cheops p,
.platform_landing_page_courses_list_item__cheops h3 {
  margin: 0;
  width: 100%;
  background: #EFEEF5;
}
@media (max-width: 760px) {
  .platform_landing_page_courses_list_item {
    width: calc(100% - 16px);
    max-width: 328px;
  }
  .platform_landing_page_courses_list_item--my_course,
  .platform_landing_page_courses_list_item--closed {
    margin: 0 auto 44px auto;
    display: block;
  }
  .platform_landing_page_courses_list_item--non_authorized {
    margin: 0 auto 16px auto;
    display: block;
  }
  .platform_landing_page_courses_list_item__cheops {
    width: calc(100% - 16px);
  }
}
.landings_wrapper,
.landings-wrapper {
  padding: 100px 40px 20px 40px;
  margin: 0 auto;
  max-width: 830px;
  box-sizing: content-box;
}
@media (max-width: 760px) {
  .landings_wrapper,
  .landings-wrapper {
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    width: 100%;
  }
}
.landings_wrapper-box,
.landings-wrapper-box {
  box-sizing: content-box;
}
.landings_wrapper__text_img,
.landings-wrapper__text_img,
.landings_wrapper__text-img,
.landings-wrapper__text-img {
  max-width: 320px;
  display: inline-block;
  padding-top: 21px;
  vertical-align: top;
  margin-right: 105px;
}
@media (max-width: 760px) {
  .landings_wrapper__text_img,
  .landings-wrapper__text_img,
  .landings_wrapper__text-img,
  .landings-wrapper__text-img {
    width: 100%;
    margin-right: 0;
  }
}
.landings_wrapper__text_img img,
.landings-wrapper__text_img img,
.landings_wrapper__text-img img,
.landings-wrapper__text-img img {
  max-width: 320px;
}
@media (max-width: 760px) {
  .landings_wrapper__text_img img,
  .landings-wrapper__text_img img,
  .landings_wrapper__text-img img,
  .landings-wrapper__text-img img {
    width: 100%;
  }
}
.landings_wrapper__text_video,
.landings-wrapper__text_video,
.landings_wrapper__text-video,
.landings-wrapper__text-video {
  width: 55%;
  max-width: 427px;
  display: inline-block;
  padding-top: 21px;
  vertical-align: top;
  margin-right: 10%;
  cursor: pointer;
}
.landings_wrapper__text_video [data-video="youtube"],
.landings-wrapper__text_video [data-video="youtube"],
.landings_wrapper__text-video [data-video="youtube"],
.landings-wrapper__text-video [data-video="youtube"],
.landings_wrapper__text_video iframe,
.landings-wrapper__text_video iframe,
.landings_wrapper__text-video iframe,
.landings-wrapper__text-video iframe {
  max-height: initial;
  width: 100%;
  aspect-ratio: 1.77777778;
}
.landings_wrapper__text_video--single,
.landings-wrapper__text_video--single,
.landings_wrapper__text-video--single,
.landings-wrapper__text-video--single,
.landings_wrapper__text_video-single,
.landings-wrapper__text_video-single,
.landings_wrapper__text-video-single,
.landings-wrapper__text-video-single {
  max-width: initial;
  width: 100%;
  margin-right: 0;
  cursor: pointer;
}
.landings_wrapper__text_video--single [data-video="youtube"],
.landings-wrapper__text_video--single [data-video="youtube"],
.landings_wrapper__text-video--single [data-video="youtube"],
.landings-wrapper__text-video--single [data-video="youtube"],
.landings_wrapper__text_video-single [data-video="youtube"],
.landings-wrapper__text_video-single [data-video="youtube"],
.landings_wrapper__text-video-single [data-video="youtube"],
.landings-wrapper__text-video-single [data-video="youtube"],
.landings_wrapper__text_video--single iframe,
.landings-wrapper__text_video--single iframe,
.landings_wrapper__text-video--single iframe,
.landings-wrapper__text-video--single iframe,
.landings_wrapper__text_video-single iframe,
.landings-wrapper__text_video-single iframe,
.landings_wrapper__text-video-single iframe,
.landings-wrapper__text-video-single iframe {
  max-height: initial;
  width: 100%;
  aspect-ratio: 1.77777778;
}
.landings_wrapper__text_video--single [data-video="storage"],
.landings-wrapper__text_video--single [data-video="storage"],
.landings_wrapper__text-video--single [data-video="storage"],
.landings-wrapper__text-video--single [data-video="storage"],
.landings_wrapper__text_video-single [data-video="storage"],
.landings-wrapper__text_video-single [data-video="storage"],
.landings_wrapper__text-video-single [data-video="storage"],
.landings-wrapper__text-video-single [data-video="storage"],
.landings_wrapper__text_video--single video,
.landings-wrapper__text_video--single video,
.landings_wrapper__text-video--single video,
.landings-wrapper__text-video--single video,
.landings_wrapper__text_video-single video,
.landings-wrapper__text_video-single video,
.landings_wrapper__text-video-single video,
.landings-wrapper__text-video-single video {
  max-height: initial;
  height: 465px;
}
@media (max-width: 1200px) {
  .landings_wrapper__text_video,
  .landings-wrapper__text_video,
  .landings_wrapper__text-video,
  .landings-wrapper__text-video {
    width: 100%;
    margin-right: 0;
    max-width: initial;
  }
  .landings_wrapper__text_video [data-video="youtube"],
  .landings-wrapper__text_video [data-video="youtube"],
  .landings_wrapper__text-video [data-video="youtube"],
  .landings-wrapper__text-video [data-video="youtube"],
  .landings_wrapper__text_video iframe,
  .landings-wrapper__text_video iframe,
  .landings_wrapper__text-video iframe,
  .landings-wrapper__text-video iframe {
    min-height: initial;
    width: 100%;
    aspect-ratio: 1.77777778;
  }
  .landings_wrapper__text_video [data-video="storage"],
  .landings-wrapper__text_video [data-video="storage"],
  .landings_wrapper__text-video [data-video="storage"],
  .landings-wrapper__text-video [data-video="storage"],
  .landings_wrapper__text_video video,
  .landings-wrapper__text_video video,
  .landings_wrapper__text-video video,
  .landings-wrapper__text-video video {
    min-height: 193px;
    height: auto;
  }
}
.landings_wrapper__title,
.landings-wrapper__title {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 50px;
}
@media (max-width: 760px) {
  .landings_wrapper__title,
  .landings-wrapper__title {
    line-height: 34px;
    font-size: 30px;
  }
}
.task {
  border-radius: 8px;
  width: 815px;
  position: relative;
  box-sizing: border-box;
  padding: 40px 50px;
  color: #232943;
  margin: 50px auto 0;
  max-width: 100%;
}
.task--grouped {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  border-bottom: 1px solid #DDE4ED;
  min-height: 0;
}
.task--grouped:first-child {
  margin-top: 50px;
  border-radius: 8px 8px 0 0;
  border: none;
}
.task--grouped:last-child {
  border-radius: 0 0 8px 8px;
}
.task--title_only {
  padding-bottom: 0;
}
.task--title_only .task__title {
  margin-bottom: 0;
}
.task--save_btn_only {
  height: 130px;
}
.task__title {
  font-size: 24px;
  margin-bottom: 40px;
  font-weight: bold;
}
.task--result {
  text-align: center;
  min-height: 520px;
  padding-bottom: 60px;
}
.task--result .task__title {
  font-size: 24px;
  margin-top: 40px;
}
.task--result .status_row {
  padding: 25px 60px 45px;
}
.task__text img {
  max-width: 100%;
}
.task__input {
  min-height: 56px;
  padding: 5px 20px;
  box-sizing: border-box;
  position: relative;
  margin: 10px -20px;
}
.task__input .multiply_answer__icon--question {
  right: 18px;
  top: 21px;
}
.task__input .multiply_answer__baloon--right {
  right: 19px !important;
}
.task__input--type_number .task__fraction_view {
  position: absolute;
  z-index: 2;
  width: calc(100% - 39px);
}
.task__input p {
  margin: 0;
}
.task__input input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.task__input input[type=checkbox] ~ label:before {
  display: inline-block;
  content: " ";
  vertical-align: text-bottom;
  border: 2px solid #A7B3C2;
  box-sizing: border-box;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  position: absolute;
  left: 16px;
  top: 17px;
}
.task__input input[type=checkbox] ~ label:hover:before {
  border: 2px solid #4B8CEE;
}
.task__input input[type=checkbox]:checked ~ label:before {
  background: #4B8CEE url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/ok.svg) 50% no-repeat;
}
.task__input input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.task__input input[type=radio] ~ label:before {
  display: inline-block;
  content: " ";
  vertical-align: text-bottom;
  border: 2px solid #A7B3C2;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  border-radius: 50%;
  position: absolute;
  left: 16px;
  top: 17px;
}
.task__input input[type=radio] ~ label:hover:before {
  border: 2px solid #4B8CEE;
}
.task__input input[type=radio]:checked ~ label:before {
  background: #4B8CEE;
  border-color: #4B8CEE;
  box-shadow: inset 0 0 0 3px #fff;
}
.task__input input[type=number],
.task__input input[type=text] {
  width: 100%;
  height: 54px;
  font-size: 16px;
  padding: 16px;
  background: #F1EDFA;
  border: 1px solid #D5DDE6;
  box-sizing: border-box;
  border-radius: 8px;
  -moz-appearance: textfield;
}
.task__input input[type=number]:focus,
.task__input input[type=text]:focus {
  z-index: 100;
  border: 2px solid #7B29C9;
  outline: none;
  background: white;
}
.task__input input[type=number]::-webkit-inner-spin-button,
.task__input input[type=text]::-webkit-inner-spin-button,
.task__input input[type=number]::-webkit-outer-spin-button,
.task__input input[type=text]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.task__input input[type=number]:invalid,
.task__input input[type=text]:invalid {
  box-shadow: none;
}
.task__input input:focus + .multiply_answer__icon--question {
  visibility: visible;
}
.task__input input:hover {
  background: #EAE0FF;
  border: 1px solid #7B29C9;
}
.task__input--error input {
  color: #FF5E73;
}
.task__input label {
  cursor: pointer;
  padding-left: 40px;
  display: block;
  line-height: 24px;
  font-size: 18px;
}
.task__input--result {
  margin: 10px -25px;
}
.task__input--result label {
  cursor: default;
}
.task__input--result label:hover:before {
  border: none !important;
}
.task__input--result input[type=number],
.task__input--result input[type=text] {
  border: none;
  border-radius: 4px;
}
.task__input--result input[type=radio] ~ label:before,
.task__input--result input[type=checkbox] ~ label:before {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  background: #69798C;
  top: 26px;
  border: none;
  border-radius: 50%;
}
.task__input--result label {
  color: #232943;
}
.task__input--result_wrong input[type=number],
.task__input--result_wrong input[type=text] {
  background: rgba(235, 87, 87, 0.2);
  color: #FF5E73;
}
.task__input--result_wrong input[type=radio]:checked ~ label:before,
.task__input--result_wrong input[type=checkbox]:checked ~ label:before {
  background: #FF5E73;
  box-shadow: none;
}
.task__input--result_wrong label {
  color: #FF5E73;
}
.task__input--result_partial_wrong input[type=radio]:checked ~ label:before,
.task__input--result_partial_wrong input[type=checkbox]:checked ~ label:before {
  background: #ff8e25;
  box-shadow: none;
}
.task__input--result_partial_wrong label {
  color: #ff8e25;
}
.task__input--result_right {
  border-radius: 8px;
}
.task__input--result_right input[type=number],
.task__input--result_right input[type=text] {
  background: rgba(91, 195, 108, 0.2);
  color: #5BC36C;
}
.task__input--result_right input[type=radio]:checked ~ label:before,
.task__input--result_right input[type=checkbox]:checked ~ label:before {
  background: #5BC36C;
  box-shadow: none;
}
.task__input--result_right label {
  color: #5BC36C;
}
.task__input--full_width input {
  width: 100% !important;
  box-sizing: border-box;
  height: 56px !important;
}
.task__input--type_checkbox {
  background: #F1EDFA;
  border-radius: 8px;
  margin: 20px 0;
  padding: 16px;
}
.task__input--type_radio {
  background: #F1EDFA;
  border-radius: 8px;
  margin: 20px 0;
  padding: 16px;
}
.task__fraction_view {
  background: #E1DFE6;
  border-radius: 12px;
  line-height: 20px;
  width: 100%;
  padding: 12px 15px 15px 15px;
  box-sizing: border-box;
  margin: 7px 0 0 0;
  display: none;
}
.task__fraction_view--error {
  background: #FFEBED;
  color: #FF5E73;
}
input:focus ~ .task__fraction_view {
  display: block;
}
.task__summary {
  line-height: 30px;
  letter-spacing: 0.02em;
  font-size: 18px;
}
.task__remain {
  line-height: 21px;
  font-size: 16px;
  text-align: center;
  color: #69798C;
  margin-top: 10px;
}
.task__footer {
  color: #69798C;
  line-height: 23px;
  font-size: 16px;
  height: 112px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  padding: 44px 50px;
  left: 0;
  right: 0;
}
.task__save_btn {
  cursor: pointer;
  float: right;
  width: 250px;
  height: 40px;
  background: #4B8CEE;
  border-radius: 20px;
  line-height: 25px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  box-sizing: border-box;
  padding: 7px 0;
  margin-top: -8px;
}
.task__save_btn:hover {
  background: #60A9FF;
}
.task__save_btn--disabled {
  background: #CAD4E0;
  color: #fff;
  cursor: default;
}
.task__save_btn--disabled:hover {
  background: #CAD4E0;
}
.task__save_btn--saved {
  background: #fcfcf9;
  color: #5BC36C;
  cursor: default;
  border: 1px solid #DDE4ED;
}
.task__save_btn--saved:hover {
  background: #fcfcf9;
}
.task__result-switch {
  float: right;
  cursor: pointer;
  line-height: 25px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #69798C;
  position: relative;
  border: 1px solid #DDE4ED;
  border-radius: 20px;
  padding: 7px 0;
  width: 305px;
  height: 40px;
  box-sizing: border-box;
  text-align: right;
  margin-right: -20px;
  background: #FCFCF9;
  user-select: none;
}
.task__result-switch:after {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/eye.svg) 50% / contain no-repeat;
  width: 22px;
  height: 15px;
  display: inline-block;
  content: " ";
  vertical-align: middle;
  margin-left: 15px;
  opacity: 0.3;
  margin-right: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.task__result-switch:hover {
  background: #F5F5F5;
}
.task__result-switch:hover:after {
  opacity: 1;
}
.task__result-switch-right_answers {
  background: #EBEBEB;
  color: #69798C;
}
.task__result-switch-right_answers:after {
  opacity: 1;
}
.task__result-switch-right_answers:hover {
  background: #F5F5F5;
}
.task__result-switch-right_answers:hover:after {
  opacity: 0.3;
}
.task--show_right_answer .task__input--result_right {
  z-index: 100;
}
.task--show_right_answer .task__result-switch {
  z-index: 100;
}
.task__answer_btn {
  width: 100%;
  border-radius: 12px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
  padding: 16px;
  box-sizing: border-box;
  border: none;
  background: #88CC00;
  color: #FFFFFF;
}
.task__answer_btn_title {
  opacity: 1;
  transition: opacity 0.2s;
}
.task__answer_btn_title--hidden {
  opacity: 0;
}
.task__answer_btn_logo {
  display: block;
  opacity: 0;
  transition: opacity 0.4s;
  position: absolute;
  top: 19px;
  width: 26px;
  height: 19px;
  left: 50%;
  margin-left: -13px;
}
.task__answer_btn_logo--visible {
  opacity: 1;
}
.task__video_link {
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 16px;
  width: 155px;
  line-height: 34px;
  margin: 40px auto;
}
.task__video_link svg {
  margin-left: 5px;
}
.task__video_close_link {
  float: right;
  cursor: pointer;
}
.task__video_iframe {
  width: 100%;
  overflow: hidden;
  height: 400px;
}
.task .iframe-youtube {
  width: 100%;
  min-height: 400px;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 760px) {
  .task {
    width: auto;
    max-width: 815px;
    padding: 40px 0;
    margin-top: 0;
  }
  .task .task__input--type_checkbox,
  .task .task__input--type_radio {
    margin-left: 0;
    margin-right: 0;
  }
  .task .iframe-youtube {
    min-height: auto;
  }
}
.task__locked {
  width: 100%;
  text-align: center;
  color: #36363E;
}
.input_select__control {
  border: 1px solid #D5DDE5;
  border-radius: 8px !important;
  overflow: hidden;
  height: 56px;
}
.input_select__value-container {
  padding: 2px 15px !important;
  margin: -2px 0 0 0;
}
.input_select__value-container,
.input_select__indicators {
  background: #F1EDFA;
  height: inherit;
}
.input_select__placeholder {
  color: #A7B3C2 !important;
  font-size: 18px;
}
.input_select__indicator-separator {
  display: none;
}
.input_select__menu {
  border-radius: 8px !important;
  border: 1px solid #D5DDE6;
  z-index: 1000 !important;
}
.task_page--timeout .input_select__menu {
  z-index: 1 !important;
}
.input_select__option {
  line-height: 28px;
  font-size: 18px;
  color: #232943 !important;
  padding: 11px 12px !important;
}
.input_select__option--is-selected,
.input_select__option--is-focused {
  background: #F2F2F2 !important;
}
.input_select__input > input {
  height: auto !important;
}
.prev-task,
.next-task {
  cursor: pointer;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/arrow.svg) 50% no-repeat;
  width: 50px;
  height: 50px;
  top: 50%;
  position: fixed;
  margin-top: -25px;
  border-radius: 50%;
}
.prev-task:hover,
.next-task:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.prev-task {
  left: 25px;
  transform: scale(-1);
}
.next-task {
  right: 25px;
}
.task_selector {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #445A76;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 0;
  padding: 10px 0;
  z-index: 100;
}
.task_selector__tasks_wrapper {
  height: 52px;
  overflow: hidden;
  transition: height 0.25s 0.1s ease-in-out;
  box-sizing: border-box;
}
.task_selector__time {
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  width: 72px;
  height: 22px;
  position: absolute;
  top: -30px;
  left: 7px;
}
.task_selector__score {
  color: #FFFFFF;
  font-size: 18px;
  text-align: right;
  width: 150px;
  height: 22px;
  position: absolute;
  top: -30px;
  right: 10px;
}
.task_selector__group {
  display: inline-block;
  margin: 0 5px;
  border-radius: 25px;
}
.task_selector__group--active {
  border: 4px solid #fff;
  margin: 0 1px;
}
.task_selector__group > div {
  margin: 1px 5px;
}
.task_selector__group > div:first-child {
  margin-left: 1px;
}
.task_selector__group > div:last-child {
  margin-right: 1px;
}
.task_selector__task {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #445A76;
  text-align: center;
  color: #fff;
  font-size: 16px;
  border-radius: 50%;
  padding: 10px 0;
  margin: 5px 5px;
  box-sizing: border-box;
  line-height: 16px;
  font-weight: 500;
}
.task_selector__task--not_saved:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: #fff;
}
.task_selector__task--saved {
  background: #4B8CEE;
  color: #fff;
  border-color: transparent;
  padding: 11px 0;
}
.task_selector__task--saved:hover {
  background: #60A9FF;
  border-color: #fff;
}
.task_selector__task--active {
  box-shadow: 0 2px 5px rgba(105, 121, 140, 0.3), inset 0 0 0 1px #fff;
  border: 5px solid #ffffff;
  margin: 0;
  width: 50px;
  height: 50px;
  padding: 11px 0;
}
.task_selector__task--result {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/plane.svg) 60% 50% no-repeat;
}
.task_selector__task--result:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #fff;
}
.task_selector__task--result_right {
  background: #5BC36C;
  color: #fff;
}
.task_selector__task--result_right:hover {
  background: #6BCF7B;
  border-color: #fff;
}
.task_selector__task--result_not_right {
  background: #FF5E73;
  color: #fff;
}
.task_selector__task--result_not_right:hover {
  background: #FF6176;
  border-color: #fff;
}
.task_selector__task--result_partial_right {
  background: #FF8E25;
  color: #fff;
}
.task_selector__task--result_partial_right:hover {
  background: #FF9D43;
  border-color: #fff;
}
.multiply_answers {
  margin: 0 0 0 -22px;
  position: relative;
}
.multiply_right_answers {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 50;
  pointer-events: none;
  visibility: hidden;
}
.multiply_right_answers--visible {
  visibility: visible;
}
.detailed_answer--closed {
  background: #F2F2F2;
}
.detailed_answer__content_title {
  text-align: center;
  color: #8C8695;
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 24px;
  margin-top: 18px;
}
@media (max-width: 760px) {
  .detailed_answer__content_title {
    font-size: 14px;
  }
}
.detailed_answer__task {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 60px;
}
@media (max-width: 760px) {
  .detailed_answer__task {
    padding: 0;
  }
}
.detailed_answer__task_header {
  font-size: 0;
  width: 100%;
  margin-bottom: 40px;
}
@media (max-width: 760px) {
  .detailed_answer__task_header {
    margin-bottom: 24px;
  }
}
.detailed_answer__task_title,
.detailed_answer__solution_title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  width: 40%;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 760px) {
  .detailed_answer__task_title,
  .detailed_answer__solution_title {
    font-size: 24px;
  }
}
.detailed_answer__task_title_description {
  font-weight: normal;
}
@media (max-width: 760px) {
  .detailed_answer__task_title_description {
    height: 0;
    visibility: hidden;
    font-size: 0;
  }
}
.detailed_answer__solution_title {
  margin-bottom: 12px;
}
.detailed_answer__task_scores {
  font-size: 30px;
  letter-spacing: 0.02em;
  text-align: right;
  width: 60%;
  display: inline-block;
  vertical-align: middle;
  color: #8C8695;
}
.detailed_answer__task_scores--graduated {
  color: #36363E;
}
@media (max-width: 760px) {
  .detailed_answer__task_scores {
    font-size: 24px;
  }
}
.detailed_answer__task_text,
.detailed_answer__solution_text {
  font-size: 16px;
  line-height: 28px;
  color: #393440;
  margin-bottom: 59px;
}
@media (max-width: 760px) {
  .detailed_answer__task_text,
  .detailed_answer__solution_text {
    font-size: 18px;
    line-height: 24px;
  }
}
.detailed_answer__solution_text {
  margin-bottom: 30px;
}
.detailed_answer__right_solution {
  margin-top: 120px;
  margin-bottom: 63px;
}
.detailed_answer__right_solution img {
  max-width: 100%;
}
.detailed_answer__task_image {
  width: 100%;
  border-radius: 6px;
  font-size: 0;
  overflow: hidden;
}
.detailed_answer__task_image .module_passing_content_inner {
  margin: 0;
}
.detailed_answer__task_image img {
  max-width: 100%;
  margin: 0;
}
.detailed_answer__task_image img:first-child:last-child {
  margin: 0;
}
.detailed_answer__messages_expander {
  width: 100%;
  text-align: center;
  padding: 16px 0;
  background: #F1EDFA;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #7B29C9;
  margin-bottom: 40px;
  cursor: pointer;
  position: relative;
  border: none;
}
.detailed_answer__messages_expander[disabled] {
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
  padding: 18px 23px;
  background: none;
  cursor: default;
  box-sizing: border-box;
}
@media (max-width: 760px) {
  .detailed_answer__messages_expander {
    margin-bottom: 16px;
  }
}
.detailed_answer__messages_expander img {
  position: absolute;
  left: 16px;
  top: calc(50% - 12px);
}
.detailed_answer__interaction {
  font-size: 0;
  text-align: right;
  margin-top: 40px;
}
@media (max-width: 760px) {
  .detailed_answer__interaction {
    margin: 0;
  }
}
@media (max-width: 760px) {
  .detailed_answer__interaction--opened {
    margin: 0;
  }
}
.detailed_answer__send_solution_button {
  background: #7B29C9;
  color: #FFFFFF;
  border-radius: 12px;
  font-size: 18px;
  line-height: 24px;
  padding: 16px 0;
  width: calc(100% - 219px);
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.detailed_answer__send_solution_button--full-width {
  width: 100%;
}
.detailed_answer__send_solution_button[disabled] {
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
  padding: 18px 23px;
  background: none;
  cursor: default;
  box-sizing: border-box;
}
@media (max-width: 760px) {
  .detailed_answer__send_solution_button {
    width: calc(100% - 58px);
    padding: 12px;
    text-transform: capitalize;
  }
  .detailed_answer__send_solution_button--full-width {
    width: 100%;
  }
}
@media (max-width: 760px) {
  .detailed_answer__send_solution_button_send {
    width: 0;
    visibility: hidden;
    font-size: 0;
  }
}
.detailed_answer__ask_button {
  background: #F1EDFA;
  border-radius: 12px;
  font-size: 18px;
  line-height: 24px;
  color: #7B29C9;
  padding: 16px 27px 16px 16px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 209px;
  border: none;
  outline: none;
}
.detailed_answer__ask_button[disabled] {
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
  padding: 18px 23px;
  background: none;
  cursor: default;
  box-sizing: border-box;
}
.detailed_answer__ask_button[disabled] img {
  display: none;
}
@media (max-width: 760px) {
  .detailed_answer__ask_button {
    padding: 12px;
    width: 48px;
  }
  .detailed_answer__ask_button span {
    width: 0;
    visibility: hidden;
    font-size: 0;
  }
}
.detailed_answer__ask_button img {
  margin-right: 10px;
  vertical-align: top;
}
.detailed_answer__ask_button img:first-child:last-child {
  margin: 0 10px 0 0;
}
@media (max-width: 760px) {
  .detailed_answer__ask_button img {
    margin: 0;
  }
}
.detailed_answer__ask_button--full_width {
  width: 100%;
}
.detailed_answer__message_form {
  margin: 40px 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border-radius: 12px;
  border: 2px solid #7B29C9;
  overflow: hidden;
}
.detailed_answer__message_form textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  outline: none;
  font-size: 18px;
  line-height: 24px;
  color: #36363E;
  border: none;
  min-width: 100%;
  max-width: 100%;
  min-height: 90px;
  display: block;
}
.detailed_answer__message_form textarea::placeholder {
  visibility: hidden;
}
@media (max-width: 761px) {
  .detailed_answer__message_form textarea {
    padding: 16px 16px 56px 16px;
    font-size: 18px;
    line-height: 22px;
  }
  .detailed_answer__message_form textarea::placeholder {
    visibility: visible;
  }
}
.detailed_answer__message_form_header {
  padding-top: 16px;
  padding-left: 16px;
}
@media (max-width: 760px) {
  .detailed_answer__message_form_header {
    height: 0;
    visibility: hidden;
  }
}
.detailed_answer__message_form_title {
  font-size: 18px;
  line-height: 24px;
  color: #8C8695;
}
.detailed_answer__message_form_footer {
  padding: 20px;
  border-top: 1px solid #d5dde5;
}
@media (max-width: 760px) {
  .detailed_answer__message_form_footer {
    padding: 20px 60px 20px 20px;
    box-sizing: border-box;
  }
}
.detailed_answer__message_form_add_file {
  color: #7B29C9;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  width: 150px;
  outline: none;
}
.detailed_answer__message_form_add_file:before {
  content: "\41F\440\438\43A\440\435\43F\438\442\435   \444\430\439\43B";
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #7B29C9;
  background: #FFFFFF;
  padding-top: 2px;
}
.detailed_answer__message_form_add_file::-webkit-file-upload-button {
  visibility: hidden;
}
@media (max-width: 760px) {
  .detailed_answer__message_form_add_file {
    width: 22px;
    height: 25px;
  }
}
.detailed_answer__message_form_add_file_label {
  color: #8C8695;
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 19px;
}
.detailed_answer__message_form_add_file_label img {
  transform: rotate(-90deg);
  vertical-align: middle;
}
.detailed_answer__message_form_add_file_label img:first-child:last-child {
  margin: 0;
  display: inline-block;
}
.detailed_answer__message_form_cancel {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  border-radius: 12px;
  background: #F1EDFA;
  color: #7B29C9;
  padding: 19px 24px;
  border: none;
  cursor: pointer;
  width: 140px;
}
@media (max-width: 760px) {
  .detailed_answer__message_form_cancel {
    width: calc(50% - 5px);
    min-width: auto;
    height: 60px;
  }
}
.detailed_answer__message_form_submit {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  border-radius: 12px;
  background: #88CC00;
  color: #FFFFFF;
  padding: 19px 24px;
  text-align: center;
  min-width: 290px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  width: calc(100% - 150px);
}
.detailed_answer__message_form_submit[disabled] {
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
  padding: 18px 23px;
  background: none;
  cursor: default;
}
.detailed_answer__message_form_submit--single {
  width: 100%;
  margin: 0;
}
@media (max-width: 760px) {
  .detailed_answer__message_form_submit {
    width: calc(50% - 5px);
    min-width: auto;
    line-height: normal;
    height: 60px;
  }
}
@media (max-width: 760px) {
  .detailed_answer__message_form_submit_review {
    width: 0;
    visibility: hidden;
    font-size: 0;
  }
}
.detailed_answer__message_image {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 6px;
  margin-right: 30px;
  margin-bottom: 30px;
  background: #ecf4ff;
  height: 170px;
  font-size: 24px;
  line-height: 20px;
  color: #7B29C9;
  text-decoration: none;
  text-transform: lowercase;
  max-width: 100%;
}
.detailed_answer__message_image img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.detailed_answer__message_image:first-child:last-child {
  margin: 0;
}
.detailed_answer__message_image_close {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  top: -16px;
  right: -16px;
  cursor: pointer;
  background: #8C8695 url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/close_cross.svg) 50% 50% no-repeat;
}
.detailed_answer__one_more_solution {
  font-size: 16px;
  line-height: 20px;
  color: #7B29C9;
  letter-spacing: 0.02em;
  text-align: center;
  margin: 60px auto 0 auto;
  cursor: pointer;
}
.detailed_answer__error {
  color: #F4425A;
  margin-bottom: 15px;
  width: 100%;
}
.detailed_answer__error_close {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/close_cross_black.svg);
  width: 14px;
  height: 14px;
  cursor: pointer;
  display: inline-block;
  vector-effect: middle;
  margin-left: 10px;
}
.detailed_answer__message_file_ext {
  padding: 16px;
  min-width: 150px;
  box-sizing: border-box;
  font-weight: 500;
}
.detailed_answer_message--graduated_20 {
  background: #fff9d9;
}
.detailed_answer_message--graduated_19 {
  background: rgba(255, 249, 217, 0.95);
}
.detailed_answer_message--graduated_18 {
  background: rgba(255, 249, 217, 0.9);
}
.detailed_answer_message--graduated_17 {
  background: rgba(255, 249, 217, 0.85);
}
.detailed_answer_message--graduated_16 {
  background: rgba(255, 249, 217, 0.8);
}
.detailed_answer_message--graduated_15 {
  background: rgba(255, 249, 217, 0.75);
}
.detailed_answer_message--graduated_14 {
  background: rgba(255, 249, 217, 0.7);
}
.detailed_answer_message--graduated_13 {
  background: rgba(255, 249, 217, 0.65);
}
.detailed_answer_message--graduated_12 {
  background: rgba(255, 249, 217, 0.6);
}
.detailed_answer_message--graduated_11 {
  background: rgba(255, 249, 217, 0.55);
}
.detailed_answer_message--graduated_10 {
  background: rgba(255, 249, 217, 0.5);
}
.detailed_answer_message--graduated_9 {
  background: rgba(255, 249, 217, 0.45);
}
.detailed_answer_message--graduated_8 {
  background: rgba(255, 249, 217, 0.4);
}
.detailed_answer_message--graduated_7 {
  background: rgba(255, 249, 217, 0.35);
}
.detailed_answer_message--graduated_6 {
  background: rgba(255, 249, 217, 0.3);
}
.detailed_answer_message--graduated_5 {
  background: rgba(255, 249, 217, 0.25);
}
.detailed_answer_message--graduated_4 {
  background: rgba(255, 249, 217, 0.2);
}
.detailed_answer_message--graduated_3 {
  background: rgba(255, 249, 217, 0.15);
}
.detailed_answer_message--graduated_2 {
  background: rgba(255, 249, 217, 0.1);
}
.detailed_answer_message--graduated_1 {
  background: rgba(255, 249, 217, 0.05);
}
.detailed_answer_message {
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  overflow: hidden;
}
@media (max-width: 760px) {
  .detailed_answer_message {
    padding: 16px;
    margin-left: -10px;
    margin-right: -10px;
  }
}
.detailed_answer_message--pending {
  background: rgba(242, 242, 242, 0.5);
}
.detailed_answer_message--graduated_full {
  background: #EAFCD7;
}
.detailed_answer_message--graduated_fail {
  background: #FFEBED;
}
.detailed_answer_message__user {
  display: inline-block;
  vertical-align: top;
  width: 60%;
}
.detailed_answer_message__user--lecturer {
  width: 100%;
}
.detailed_answer_message__scores {
  letter-spacing: 0.02em;
  color: #8C8695;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  width: 40%;
}
@media (max-width: 760px) {
  .detailed_answer_message__scores {
    font-size: 14px;
    line-height: 20px;
  }
}
.detailed_answer_message__scores--graduated {
  color: #36363E;
  font-weight: 600;
}
@media (max-width: 760px) {
  .detailed_answer_message__scores_solution {
    width: 0;
    visibility: hidden;
    font-size: 0;
  }
}
.detailed_answer_message__avatar {
  background: #B5B38B;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: #FFFFFF;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  padding-top: 7px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin-right: 16px;
}
@media (max-width: 760px) {
  .detailed_answer_message__avatar {
    width: 36px;
    height: 36px;
    padding-top: 6px;
    font-size: 14px;
    line-height: 24px;
  }
}
.detailed_answer_message__avatar img:first-child:last-child {
  margin: 0;
  display: inline-block;
}
.detailed_answer_message__avatar--lecturer {
  background: #7B29C9;
}
.detailed_answer_message__user_text {
  display: inline-block;
  vertical-align: top;
}
.detailed_answer_message__user_name {
  color: #7B29C9;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-weight: 600;
}
@media (max-width: 760px) {
  .detailed_answer_message__user_name {
    font-size: 14px;
    line-height: 18px;
  }
}
.detailed_answer_message__date {
  color: #8C8695;
  font-size: 18px;
  line-height: 24px;
}
@media (max-width: 760px) {
  .detailed_answer_message__date {
    font-size: 16px;
    line-height: 22px;
  }
}
.detailed_answer_message__content {
  padding-top: 15px;
}
.detailed_answer_message__content_text {
  font-size: 18px;
  line-height: 24px;
  color: #36363E;
}
.detailed_answer_message__content_text div,
.detailed_answer_message__content_text p {
  display: inline;
}
.detailed_answer_message__content_text_solution {
  font-weight: 600;
}
.detailed_answer_message__content_imgs {
  margin-top: 20px;
}
.detailed_answer_message__content_image {
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 6px;
  height: 170px;
  overflow: hidden;
  max-width: 100%;
  background: #ecf4ff;
  font-size: 24px;
  line-height: 20px;
  color: #7B29C9;
  text-decoration: none;
  text-transform: lowercase;
}
.detailed_answer_message__content_image:last-child {
  margin-right: 0;
}
.detailed_answer_message__content_image img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.detailed_answer_message__content_image img:first-child:last-child {
  margin: 0;
}
@media (max-width: 760px) {
  .detailed_answer_message__content_image {
    height: 129px;
  }
}
.detailed_answer_message__comment {
  margin-top: 60px;
}
.header_menu {
  position: fixed;
  top: -100%;
  right: 0;
  transition: top 400ms;
  width: 320px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0 16px;
  overflow: hidden;
  z-index: 999;
}
@media (max-width: 760px) {
  .header_menu {
    width: 100%;
  }
}
.header_menu--opened {
  top: 0;
}
.header_menu__item {
  display: block;
  cursor: pointer;
  width: 100%;
  height: 56px;
  background: #FFFFFF;
  border-bottom: 1px solid #F2F2F2;
  box-sizing: border-box;
  padding: 16px 16px 0 19px;
  position: relative;
  color: #000;
  text-decoration: none;
}
.header_menu__item:last-child,
.header_menu__item:only-child {
  border: none;
}
.header_menu__item--auth {
  background: #6c4dc4;
  padding: 19px 0 0 19px;
  color: #FFFFFF;
}
.header_menu__item--logout {
  background: #F2F2F2;
  color: #000;
  line-height: 18px;
  font-size: 12px;
  padding: 19px 0 0 19px;
  cursor: default;
}
.header_menu__item_title {
  line-height: 18px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  padding-top: 3px;
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header_menu__item_widget {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
}
.header_menu__item_widget_notifications {
  margin-top: -18px;
}
.header_menu__logout {
  position: absolute;
  top: 19px;
  right: 16px;
  line-height: 18px;
  font-size: 16px;
  cursor: pointer;
}
span.header_menu__user_name,
a.header_menu__user_name {
  padding-right: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  color: #7B29C9;
  line-height: 18px;
  font-size: 16px;
}
.header_menu__close {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/close_cross_black.svg) 50% 50% no-repeat;
}
.header_menu__fingerprint {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/fingerprint.svg) 50% 50% no-repeat;
}
.header_menu__auth_title {
  line-height: 18px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
}
.header_menu__item--auth .header_menu__auth_title {
  color: #FFFFFF;
}
.header_menu--authorized .header_menu__item--auth {
  background: #6C4DC4;
}
.certificates_page {
  background: #6c4dc4;
  position: relative;
  min-height: 100vh;
}
.certificates_page__block_wrapper {
  max-width: 860px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}
.certificates_page__content {
  background: #fcfcf9;
  border-radius: 0 0 16px 16px;
  padding-top: 95px;
  padding-bottom: 124px;
  box-sizing: border-box;
  min-height: calc(100vh - 132px);
  font-size: 0;
}
.certificates_page__title {
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #000000;
  max-width: 745px;
  margin-bottom: 62px;
}
.certificates_page__about {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_certificate.png) 0 50% no-repeat;
  margin-top: 110px;
  padding-left: 50%;
  min-height: 245px;
}
.certificates_page__about_title {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: #000000;
  max-width: 745px;
  margin-bottom: 62px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
}
.certificates_page__about_text {
  font-size: 16px;
  line-height: 22px;
  padding-left: 5px;
}
@media (max-width: 760px) {
  .certificates_page__block_wrapper {
    width: 100%;
    padding: 0;
  }
  .certificates_page__title {
    margin-left: 32px;
    margin-right: 32px;
    line-height: 30px;
    font-size: 30px;
  }
  .certificates_page__about {
    padding: 270px 30px 0;
    background-position: 50% 0;
  }
  .certificates_page__about_title {
    line-height: 30px;
    font-size: 30px;
  }
}
.certificate_page {
  background: #6c4dc4;
  position: relative;
  min-height: 100vh;
}
.certificate_page__logo {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 35px;
  width: 175px;
  display: block;
  vertical-align: top;
}
.certificate_page-cluster-cpm .certificate_page__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm.png);
}
.certificate_page-cluster-cpm .certificate_page__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-white.png);
}
.certificate_page-cluster-cpm .certificate_page__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-small.svg);
}
.certificate_page-cluster-cpm .certificate_page__logo-white.certificate_page__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-small-white.svg);
}
.certificate_page-cluster-sirius .certificate_page__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius.png);
}
.certificate_page-cluster-sirius .certificate_page__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-white.png);
}
.certificate_page-cluster-sirius .certificate_page__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-small.svg);
}
.certificate_page-cluster-sirius .certificate_page__logo-white.certificate_page__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-small-white.svg);
}
.certificate_page-cluster-corp .certificate_page__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp.png);
}
.certificate_page-cluster-corp .certificate_page__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-white.png);
}
.certificate_page-cluster-corp .certificate_page__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-small.svg);
}
.certificate_page-cluster-corp .certificate_page__logo-white.certificate_page__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-small-white.svg);
}
.certificate_page-cluster-univ .certificate_page__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ.png);
}
.certificate_page-cluster-univ .certificate_page__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-white.png);
}
.certificate_page-cluster-univ .certificate_page__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-small.svg);
}
.certificate_page-cluster-univ .certificate_page__logo-white.certificate_page__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-small-white.svg);
}
.certificate_page__bubble {
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  background-color: #735fcd;
  border-radius: 16px;
  max-width: 300px;
  box-sizing: border-box;
  padding: 12px 20px;
  margin-top: 50px;
  margin-bottom: 60px;
  position: relative;
}
.certificate_page__bubble:before {
  content: ' ';
  display: block;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/bubble_top.svg);
  width: 40px;
  height: 13px;
  position: absolute;
  top: -11px;
  left: 30px;
}
.certificate_page__bubble a {
  color: inherit;
}
.certificate_page__block_wrapper {
  max-width: 860px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}
.certificate_page__content {
  background: #fcfcf9;
  border-radius: 0 0 16px 16px;
  padding-top: 95px;
  padding-bottom: 124px;
  box-sizing: border-box;
  min-height: calc(100vh - 132px);
  font-size: 0;
}
.certificate_page__content--no_header {
  padding-top: 50px;
}
.certificate_page__title {
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 20px;
}
.certificate_page__title a {
  text-decoration: none;
}
.certificate_page__title a:hover {
  text-decoration: underline;
}
.certificate_page__title2 {
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 20px;
}
.certificate_page__course_date {
  font-size: 16px;
  line-height: 24px;
  color: #828282;
  margin-top: 20px;
}
.certificate_page__social {
  font-size: 0;
  margin: 30px 0 60px;
}
.certificate_page__social > .react-share__ShareButton {
  display: inline-block;
  margin: 0 14px;
  cursor: pointer;
}
.certificate_page__social > .react-share__ShareButton:first-child {
  margin-left: 0;
}
.certificate_page__social > .react-share__ShareButton:last-child {
  margin-right: 0;
}
.certificate_page__social > .react-share__ShareButton:hover {
  opacity: 0.8;
}
.certificate_page__img {
  margin-top: 20px;
  width: 380px;
  margin-bottom: 30px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  max-width: 100%;
}
.certificate_page__img2 {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  max-width: 390px;
  width: 50%;
}
.certificate_page__links {
  margin-top: 35px;
  font-size: 16px;
  line-height: 30px;
  color: #232943;
}
.certificate_page__links span {
  margin-right: 50px;
}
.certificate_page__links span:last-child {
  margin-right: 0;
}
.certificate_page__links a {
  text-decoration: none;
}
.certificate_page__links a:hover {
  text-decoration: underline;
}
.certificate_page__row {
  font-size: 0;
  padding: 0 40px;
  max-width: 1920px;
  margin: 0 auto;
}
.certificate_page__col {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.certificate_page__col:last-child {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.certificate_page__qr {
  margin-top: 55px;
  max-width: 60%;
}
.certificate_page__link {
  font-size: 16px;
  line-height: 30px;
  color: #232943;
}
.certificate_page__link input {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 48px;
  width: 455px;
  padding: 0 13px;
  margin-top: 20px;
  max-width: 100%;
}
@media (max-width: 1440px) {
  .certificate_page__title2 {
    font-size: 36px;
    line-height: 40px;
  }
}
@media (max-width: 1440px) {
  .certificate_page__title2 {
    font-size: 30px;
    line-height: 34px;
  }
  .certificate_page_col {
    vertical-align: middle;
  }
}
@media (max-width: 760px) {
  .certificate_page__block_wrapper {
    width: 100%;
    padding: 0 20px;
  }
  .certificate_page__title {
    line-height: 30px;
    font-size: 30px;
  }
  .certificate_page__links {
    font-size: 14px;
    line-height: 30px;
  }
  .certificate_page__links span {
    margin-right: 25px;
  }
  .certificate_page__course_date {
    font-size: 14px;
    line-height: 24px;
  }
  .certificate_page__social {
    text-align: center;
  }
  .certificate_page__social > div {
    margin: 0 8px;
  }
  .certificate_page__row {
    padding: 0 20px;
  }
  .certificate_page__col {
    display: block;
    width: 100%;
  }
  .certificate_page__col:first-child {
    margin-bottom: 30px;
  }
  .certificate_page__img2 {
    width: 100%;
  }
}
.course_certificates {
  background: #f0f0f0;
  border-radius: 16px;
  margin: 40px 0;
}
.course_certificates__course {
  font-size: 30px;
  line-height: 38px;
  background: #ffffff;
  padding: 20px 30px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  margin-bottom: 5px;
}
.course_certificates__course a {
  text-decoration: none;
}
.course_certificates__course a:hover {
  text-decoration: underline;
}
.course_certificates__course_date {
  font-size: 16px;
  line-height: 24px;
  color: #828282;
  margin-top: 10px;
}
.course_certificates__certificate {
  font-size: 18px;
  line-height: 28px;
  padding: 20px 30px;
}
.course_certificates__certificate--ready,
.course_certificates__certificate--pending,
.course_certificates__certificate--availableAfter,
.course_certificates__certificate--quizAvailable,
.course_certificates__certificate--available {
  box-shadow: 0 20px 32px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  margin-bottom: 5px;
  background: #fff;
  padding-top: 55px;
  margin-top: -30px;
}
.course_certificates__copy_link {
  cursor: pointer;
}
.course_certificates__copy_link:hover {
  text-decoration: underline;
}
.course_certificates__certificate_img {
  width: 40px;
  height: 60px;
  margin-top: -35px;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.course_certificates__btn {
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 28px;
  background: #2f80ed;
  color: #fff;
  border: none;
  padding: 0 20px;
  cursor: pointer;
  outline: none;
  margin-left: 10px;
}
.course_certificates__btn:hover {
  background: #60A9FF;
  color: #fff;
}
.course_certificates__btn:disabled {
  background: #e3e5e8;
  cursor: default;
  color: #fff;
}
.course_certificates__btn:disabled:hover {
  background: #e3e5e8;
}
.course_certificates__btn--light {
  background: rgba(47, 128, 237, 0.1);
  color: #2f80ed;
}
.course_certificates__certificate_target {
  font-size: 16px;
  line-height: 26px;
  color: #828282;
  float: left;
  margin-top: 5px;
}
.course_certificates__certificate_target > div {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 5px;
}
.course_certificates__certificate_target a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.course_certificates__certificate_target a:hover {
  text-decoration: underline;
}
.course_certificates__certificate_target span {
  color: #000;
}
.course_certificates__certificate_right {
  float: right;
  text-align: right;
  font-size: 16px;
  line-height: 26px;
  color: #828282;
}
.course_certificates__certificate_right > span {
  color: #000;
}
.course_certificates__certificate_right svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.course_certificates__name {
  margin-bottom: 5px;
}
.course_certificates__name--bold {
  font-weight: 500;
}
.course_certificates__name a {
  text-decoration: none;
}
.course_certificates__name a:hover {
  text-decoration: underline;
}
.course_certificates__name svg {
  width: 24px;
  height: 24px;
  vertical-align: sub;
  margin-right: 5px;
}
.course_certificates__certificate_progress {
  background: #e3e5e8;
  border-radius: 16px;
  height: 4px;
  margin-top: 10px;
}
.course_certificates__certificate_progress > div {
  background: #2f80ed;
  border-radius: 2px;
  height: inherit;
}
.course_certificates__row:after {
  display: block;
  content: ' ';
  clear: both;
}
.course_certificates__reject_reason {
  background: #efeef5 url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_advising.svg) 15px 25px no-repeat;
  backdrop-filter: blur(32px);
  border-radius: 4px 32px 32px 32px;
  padding: 25px 35px 25px 80px;
  font-size: 14px;
  line-height: 18px;
  max-width: 410px;
  box-sizing: border-box;
  text-align: left;
  margin: 15px 0 0;
  min-height: 115px;
}
.course_certificates__reject_reason div {
  color: #9194a1;
}
@media (max-width: 760px) {
  .course_certificates__course {
    font-size: 24px;
    line-height: 28px;
    padding: 20px 15px;
  }
  .course_certificates__course_date {
    font-size: 14px;
    line-height: 18px;
  }
  .course_certificates__certificate {
    font-size: 18px;
    line-height: 24px;
    padding: 20px 15px;
  }
  .course_certificates__certificate_target {
    font-size: 14px;
    line-height: 18px;
  }
  .course_certificates__certificate_stats {
    margin-top: 20px;
  }
  .course_certificates__certificate_right {
    margin-top: 20px;
    font-size: 14px;
    line-height: 18px;
  }
  .course_certificates__btn {
    margin-top: 20px;
  }
  .course_certificates__certificate_img {
    display: none;
  }
  .course_certificates__certificate_target_link {
    display: none !important;
  }
}
.certificates_page_form {
  text-align: center;
  max-width: 640px;
  margin: 0 auto;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_certificate.svg) 50% 0 no-repeat;
  padding: 130px 15px 0;
}
.certificates_page_form__title {
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -0.02em;
}
.certificates_page_form__subtitle {
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.certificates_page_form__answer {
  background: #efeef5;
  backdrop-filter: blur(32px);
  border-radius: 4px 32px 32px 32px;
  padding: 25px 35px;
  font-size: 14px;
  line-height: 18px;
  width: 300px;
  box-sizing: border-box;
  text-align: left;
  margin: 30px auto 0;
}
.certificates_page_form__answer div {
  color: #9194a1;
}
.certificates_page_form__agreement_text {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #828282;
  max-width: 300px;
  margin: 35px auto 0;
}
.certificates_page_form__input_text {
  border-radius: 16px 16px 0 0;
  width: 300px;
  margin: 10px auto;
  display: flex;
  font-size: 14px;
  height: 48px;
}
.certificates_page_form__input_text--textarea {
  height: 150px;
}
.certificates_page_form__input_text textarea {
  resize: none;
  position: relative;
}
.certificates_page_form__input_text .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 28px 0 0 28px;
  width: 28px;
  background: white;
}
[dir='rtl'] .certificates_page_form__input_text .mdc-notched-outline .mdc-notched-outline__leading,
.certificates_page_form__input_text .mdc-notched-outline .mdc-notched-outline__leading[dir='rtl'] {
  border-radius: 0 28px 28px 0;
}
.certificates_page_form__input_text .mdc-notched-outline .mdc-notched-outline__notch {
  max-width: calc(100% - 28px * 2);
  background: white;
}
.certificates_page_form__input_text .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0 28px 28px 0;
  background: white;
}
[dir='rtl'] .certificates_page_form__input_text .mdc-notched-outline .mdc-notched-outline__trailing,
.certificates_page_form__input_text .mdc-notched-outline .mdc-notched-outline__trailing[dir='rtl'] {
  border-radius: 28px 0 0 28px;
}
.certificates_page_form__input_text .mdc-text-field__input {
  padding-left: 32px;
  padding-right: 0;
}
[dir='rtl'] .certificates_page_form__input_text .mdc-text-field__input,
.certificates_page_form__input_text .mdc-text-field__input[dir='rtl'] {
  padding-left: 0;
  padding-right: 32px;
}
.certificates_page_form__input_text + .mdc-text-field-helper-line {
  padding-left: 32px;
  padding-right: 28px;
}
[dir='rtl'] .certificates_page_form__input_text + .mdc-text-field-helper-line,
.certificates_page_form__input_text + .mdc-text-field-helper-line[dir='rtl'] {
  padding-left: 28px;
  padding-right: 32px;
}
.certificates_page_form .mdc-text-field--outlined .mdc-floating-label {
  top: 15px;
}
.certificates_page_form__btn {
  background: #e0e0e0;
  box-shadow: 0 4px 8px rgba(35, 41, 67, 0.25);
  border-radius: 28px;
  width: 300px;
  height: 48px;
  margin: 50px auto 20px;
  box-sizing: border-box;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.certificates_page_form__btn--enabled {
  background: linear-gradient(245.31deg, #40ccfb 48.72%, #4b8cee 98.18%), #56ccf2;
  background-size: 200%;
  background-position: 0 50%;
  transition: all 500ms, background 100ms;
}
.certificates_page_form__btn--enabled:hover {
  background-position: 100% 50%;
}
.certificates_page_form__btn--enabled:active {
  background: #4b8eee;
}
.certificates_page_form__btn div {
  margin: 0;
  padding: 16px 0 0;
  font-size: 14px;
  font-weight: normal;
}
.feedback_page__title {
  width: 550px;
}
@media (max-width: 760px) {
  .feedback_page__title {
    width: 288px;
    margin-left: auto;
    margin-right: auto;
  }
}
.feedback_page__pic {
  display: inline-block;
  vertical-align: top;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_feedback.svg) 50% 50% no-repeat;
  width: 326px;
  height: 200px;
  background-size: 100%;
}
@media (max-width: 760px) {
  .feedback_page__pic {
    width: 296px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.feedback_page__text_row {
  padding-top: 40px;
  line-height: 22px;
  font-size: 16px;
  color: #000000;
}
.feedback_page__text_row_frame_pic {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/double_frame.svg) no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  cursor: pointer;
}
#feedback_page__mail {
  position: absolute;
  left: -999px;
  top: -999px;
  opacity: 0;
}
.section_page {
  min-height: 100vh;
  background: #6C4DC4;
  position: relative;
  padding-bottom: 132px;
}
.section_page__content {
  margin: 10px 54px 0 54px;
}
@media (max-width: 760px) {
  .section_page__content {
    margin-left: auto;
    margin-right: auto;
  }
}
.section_page__task_list {
  font-size: 0;
  margin-bottom: 60px;
}
.section_page .landings_footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.task_card {
  background: #FFFFFF;
  border-radius: 16px;
  width: 369px;
  height: 270px;
  margin: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 18px 15px 20px 20px;
  box-sizing: border-box;
  text-decoration: none;
}
@media (max-width: 760px) {
  .task_card {
    display: block;
    max-width: 369px;
    width: calc(100% - 10px);
    margin-right: auto;
    margin-left: auto;
  }
}
.task_card--not_available {
  background: #E0E0E0;
}
.task_card--green {
  background: #88CC00;
}
.task_card--yellow {
  background: #FFD000;
}
.task_card--red {
  background: #FF6B7E;
}
.task_card__task_number {
  font-size: 18px;
  line-height: 24px;
  color: #393440;
  margin-bottom: 4px;
}
.task_card__task_number--big {
  font-size: 48px;
  line-height: 48px;
}
.task_card__scores {
  position: absolute;
  top: 18px;
  right: 15px;
  color: #36363E;
  font-size: 18px;
  line-height: 24px;
}
.task_card__answer_text {
  font-size: 18px;
  line-height: 24px;
  color: #393440;
  height: 120px;
  overflow: hidden;
  position: relative;
}
.task_card__answer_text--small {
  height: 72px;
}
.task_card__answer_text p {
  margin: 0;
}
.task_card__answer_text img {
  display: none;
}
.task_card__answer_text:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  height: 24px;
  background: linear-gradient(transparent, #FFFFFF 70%);
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #ffffff 70%);
}
.task_card__answer_text--not_available:after {
  background: linear-gradient(transparent, #E0E0E0 70%);
  background: -webkit-linear-gradient(rgba(224, 224, 224, 0), #e0e0e0 70%);
}
.task_card__answer_text--green:after {
  background: linear-gradient(transparent, #88CC00 70%);
  background: -webkit-linear-gradient(rgba(136, 204, 0, 0), #88cc00 70%);
}
.task_card__answer_text--yellow:after {
  background: linear-gradient(transparent, #FFD000 70%);
  background: -webkit-linear-gradient(rgba(255, 208, 0, 0), #ffd000 70%);
}
.task_card__answer_text--red:after {
  background: linear-gradient(transparent, #FF6B7E 70%);
  background: -webkit-linear-gradient(rgba(255, 107, 126, 0), #ff6b7e 70%);
}
.task_card__answer_text_inner--bold {
  font-weight: 600;
}
.task_card__images {
  margin-top: 10px;
}
.task_card__images_item {
  color: #36363E;
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background: #FFFFFF;
  padding: 5px 11px;
}
.task_card__images_item--bold {
  font-weight: 600;
}
.task_card__images_item--counter {
  padding-left: 7px;
  padding-right: 7px;
}
.task_card__current_score {
  font-size: 48px;
  line-height: 48px;
  color: #FFD000;
}
.task_card__current_score--bold {
  font-weight: 600;
}
.task_card__current_score--white {
  color: #FFFFFF;
}
.task_card__time_text {
  position: absolute;
  left: 20px;
  bottom: 20px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #36363E;
}
.task_card__button {
  position: absolute;
  bottom: 18px;
  right: 18px;
  background: #88CC00;
  border-radius: 12px;
  height: 44px;
  padding: 13px 18px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  font-weight: 600;
}
.task_card__button--continue {
  background: #F1EDFA;
  color: #7B29C9;
  font-weight: normal;
}
.course_widget_content__wrapper {
  margin: 0 auto;
  max-width: 600px;
  font-size: 22px;
}
.course_widget_content__wrapper .notification_page__content {
  font-size: 22px;
  line-height: normal;
}
.course_widget_content__wrapper .notification_page__content p {
  line-height: 1.3em;
}
@media (max-width: 760px) {
  .course_widget_content__wrapper {
    padding: 0 15px;
  }
  .course_widget_content__wrapper .notification_page__content {
    font-size: 18px;
    line-height: normal;
  }
  .course_widget_content__wrapper h2 {
    font-size: 1.3em;
  }
}
.course_widget_content__contest_body {
  margin-top: 68px;
}
.course_widget_content__contest_buttons {
  background: #E1DFE6;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.course_widget_content__contest_buttons button {
  transition: all 100ms;
}
.course_widget_content__contest_buttons button {
  background: #88CC00;
  border: 1px solid transparent;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(84, 120, 8, 0.15);
  border-radius: 8px;
  font-size: 18px;
  line-height: 22px;
  padding: 17px 22px;
  color: white;
  min-width: 240px;
}
.course_widget_content__contest_buttons button:hover {
  background: #55AB00;
}
.course_widget_content__contest_buttons--disable button {
  border: 1px solid white;
  background: transparent !important;
  box-shadow: 0 8px 16px transparent !important;
}
.course_widget_content__contest_buttons div {
  box-sizing: border-box;
  font-size: 18px;
  line-height: 22px;
  color: #36363E;
}
@media (max-width: 760px) {
  .course_widget_content__contest_buttons {
    display: block;
    text-align: center;
  }
  .course_widget_content__contest_buttons button {
    margin-top: 15px;
  }
}
