.detailed_answer--closed {
  background: #F2F2F2;
}
.detailed_answer__task_text,
.detailed_answer__solution_text {
  font-size: 24px;
  line-height: 36px;
  color: #393440;
  margin-bottom: 59px;
}
@media (max-width: 768px) {
  .detailed_answer__task_text,
  .detailed_answer__solution_text {
    font-size: 18px;
    line-height: 24px;
  }
}
.detailed_answer__solution_text {
  margin-bottom: 30px;
}
.detailed_answer__task_image {
  width: 100%;
  border-radius: 6px;
  font-size: 0;
  overflow: hidden;
}
.detailed_answer__task_image .module_passing_content_inner {
  margin: 0;
}
.detailed_answer__task_image img {
  max-width: 100%;
  margin: 0;
}
.detailed_answer__task_image img:first-child:last-child {
  margin: 0;
}
.detailed_answer__message_form {
  margin: 40px 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background: #fff;
  border: 1px solid #d5dde5;
  border-radius: 12px;
  min-height: 200px;
  overflow: hidden;
}
.detailed_answer__message_form textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  min-height: 200px;
  outline: none;
  font-size: 18px;
  line-height: 24px;
  color: #36363E;
  background: transparent;
  border: none;
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 768px) {
  .detailed_answer__message_form textarea {
    padding: 16px 16px 56px 16px;
    font-size: 18px;
    line-height: 22px;
  }
}
@media (min-width: 769px) {
  .detailed_answer__message_form textarea::placeholder {
    visibility: hidden;
  }
}
.detailed_answer__message_form_header {
  padding-top: 16px;
  padding-left: 16px;
}
.detailed_answer__message_form_title {
  font-size: 18px;
  line-height: 24px;
  color: #8C8695;
}
.detailed_answer__message_form_footer {
  padding: 10px;
  border-top: 1px solid #d5dde5;
}
.detailed_answer__message_form_add_file {
  display: inline;
  color: #2276F4;
  position: relative;
  width: 150px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
}
.detailed_answer__message_form_add_file input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}
.detailed_answer__message_form_add_file img {
  transform: rotate(-90deg);
  margin-left: 10px;
}
.detailed_answer__error {
  color: #F4425A;
  margin-bottom: 15px;
  width: 100%;
}
.detailed_answer__error_close {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/close_cross_black.svg);
  width: 14px;
  height: 14px;
  cursor: pointer;
  display: inline-block;
  vector-effect: middle;
  margin-left: 10px;
}
.detailed_answer__message_form_add_file_label {
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 19px;
}
.detailed_answer > .circular-progress {
  margin: 35px auto;
}
.detailed_answer__message_image {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 10px;
  background: #ecf4ff;
  height: 170px;
  width: 150px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 24px;
  line-height: 20px;
  color: #2276F4;
  text-decoration: none;
  text-transform: lowercase;
  max-width: 100%;
}
.detailed_answer__message_image:hover::after {
  content: '';
  height: 100%;
  width: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/download-icon.svg) no-repeat 10px 144px;
}
.detailed_answer__message_image img {
  height: 170px;
  max-width: 150px;
  object-fit: cover;
  border-radius: 8px;
}
.detailed_answer__message_file_ext {
  padding: 16px;
  min-width: 150px;
  box-sizing: border-box;
  font-weight: 500;
}
.detailed_answer__message_image_close {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  top: -16px;
  right: -16px;
  cursor: pointer;
  background: #8C8695 url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/close_cross.svg) 50% 50% no-repeat;
}
.detailed_answer__one_more_solution {
  font-size: 16px;
  line-height: 20px;
  color: #7B29C9;
  letter-spacing: 0.02em;
  text-align: center;
  margin: 60px auto 0 auto;
  width: 300px;
  cursor: pointer;
}
.detailed_answer__result_answer {
  margin: 20px;
  white-space: pre-wrap;
}
.detailed_answer--has_result .detailed_answer__message_form {
  border: none;
  padding: 0;
  min-height: 56px;
}
.detailed_answer--has_result .detailed_answer__message_form_footer {
  border: none;
}
.detailed_answer--verdict-none .detailed_answer__message_form {
  background: #FFEBED;
}
.detailed_answer--verdict-wrong .detailed_answer__message_form {
  background: #FFEBED;
}
.detailed_answer--verdict-notReady .detailed_answer__message_form {
  border: 1px solid #d5dde5;
}
.detailed_answer--verdict-partly .detailed_answer__message_form {
  background: #FFF5D9;
}
.detailed_answer--verdict-ok .detailed_answer__message_form {
  background: #EBF7DF;
}
.detailed_answer--verdict-annulled .detailed_answer__message_form {
  border: 1px solid #d5dde5;
}
.detailed_answer--verdict-noneUnscored .detailed_answer__message_form {
  background: #ffffff;
  border: 1px solid #E1DFE6;
}
.detailed_answer--verdict-unscored .detailed_answer__message_form {
  background: #ffffff;
  border: 1px solid #E1DFE6;
}
