.error-page-4xx {
  background-color: #FCFCF9;
  padding: 32px 16px;
}
.error-page-4xx__content {
  margin: 0 auto;
}
.error-page-4xx__logo {
  width: 344px;
  height: 240px;
  margin: 160px auto 8px auto;
  background-size: cover;
  background-repeat: no-repeat;
}
.error-page-4xx__logo-408 {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_wires.svg);
}
.error-page-4xx__logo-409 {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_locked_wait.png);
}
.error-page-4xx__logo-423 {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_locked.png);
}
.error-page-4xx__logo-429 {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_clock_attention.png);
}
.error-page-4xx__description {
  text-align: center;
}
.error-page-4xx__description-title {
  line-height: normal;
  font-size: 36px;
  margin-bottom: 16px;
}
.error-page-4xx__description-message {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
.error-page-4xx__button {
  max-width: max-content;
  margin: 32px auto;
}
@media (max-width: 768px) {
  .error-page-4xx__logo {
    width: 256px;
    height: 180px;
    margin-top: 80px;
  }
  .error-page-4xx__description-title {
    font-size: 24px;
  }
  .error-page-4xx__description-message {
    font-size: 14px;
    line-height: 20px;
  }
}
