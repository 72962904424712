.teacher-grant-access__modal {
  user-select: none;
}
.teacher-grant-access__modal .ui-modal__header {
  align-items: start;
}
.teacher-grant-access__modal .ui-modal__header .ui-text-control-primary {
  padding-left: 8px;
}
.teacher-grant-access__modal .ui-modal__header .ui-button-mode-secondary {
  background-color: transparent;
}
.teacher-grant-access__modal .ui-modal__body {
  padding: 8px;
}
.teacher-grant-access__form {
  min-height: 100px;
  flex-grow: 1;
}
.teacher-grant-access__section-row {
  padding: 12px 8px;
}
.teacher-grant-access__checkbox-row {
  padding: 8px 16px;
  border-radius: 8px;
}
.teacher-grant-access__checkbox-row:hover {
  background-color: #F7F7F7;
}
.teacher-grant-access__checkbox-row .ui-checkbox,
.teacher-grant-access__checkbox-row-disabled {
  pointer-events: none;
}
