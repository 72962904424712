.solve_result {
  position: fixed;
  z-index: 1000;
  background-position: 50% 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
}
.solve_result--verdict-ok {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/verdict_bg_ok.svg);
}
.solve_result--verdict-ok_first_try {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/verdict_bg_ok_first_try.svg);
}
.solve_result--verdict-wrong {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/verdict_bg_wrong.svg);
}
.solve_result--verdict-repeated {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/verdict_bg_repeated.svg);
}
.solve_result--verdict-partly {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/verdict_bg_partly.svg);
}
.solve_result--verdict-partly_can_again {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/verdict_bg_partly_can_again.svg);
}
.solve_result--verdict-wrong_can_again {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/verdict_bg_wrong_can_again.svg);
}
