.prof_cheops_message {
  position: fixed;
  top: 20px;
  right: 184px;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 32px 4px 32px 32px;
  width: 412px;
  padding: 26px 16px 20px 32px;
  box-sizing: border-box;
  z-index: 99;
}
@media (max-width: 760px) {
  .prof_cheops_message {
    top: 56px;
    width: calc(100% - 20px);
    max-width: 412px;
    right: 10px;
    font-size: 0;
  }
}
.prof_cheops_message__title {
  font-family: "PT Root UI", Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #9194a1;
}
.prof_cheops_message__text {
  font-family: "PT Root UI", Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #000000;
  cursor: pointer;
}
.prof_cheops_message__block {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 56px);
}
.prof_cheops_message__cheops {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_advising.svg) no-repeat;
  width: 56px;
  height: 65px;
  display: inline-block;
  vertical-align: top;
}
.prof_cheops_message__close {
  width: 32px;
  height: 32px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cross.svg) 50% 50% no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  font-size: 0;
  padding-top: 9px;
  cursor: pointer;
}
.prof_cheops_message__link {
  color: #4b8cee;
  cursor: pointer;
}
.prof_cheops_message__link--underline {
  text-decoration: underline;
}
.prof_cheops_message__text:hover .prof_cheops_message__link {
  opacity: 1;
  text-decoration: underline;
}
