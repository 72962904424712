.update_popup {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.38);
}
.update_popup__window {
  max-width: 360px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  border-radius: 16px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 16px;
  box-sizing: border-box;
  font-family: "PT Root UI", Arial, sans-serif;
}
@media (max-width: 760px) {
  .update_popup__window {
    display: block;
    width: auto;
  }
}
.update_popup__window_title {
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  padding: 0 120px 17px 0;
}
.update_popup__window_text {
  font-size: 18px;
  line-height: 24px;
  color: #36363E;
  position: relative;
  padding-right: 120px;
}
.update_popup__window_cheops {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 106px;
  height: 108px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cheops_mechanic.svg) 50% 50% no-repeat;
}
.update_popup__window_footer {
  text-align: right;
  font-size: 0;
  margin-top: 15px;
}
.update_popup__window_footer button {
  font-family: "PT Root UI", Arial, sans-serif;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background: #F1EDFA;
  border-radius: 8px;
  height: 36px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #7B29C9;
  margin-left: 8px;
  outline: none;
  border: 0;
  transition: all 0.1s ease-in-out;
}
.update_popup__window_footer button:hover {
  background: #EAE0FF;
}
.update_popup__window_footer button[disabled] {
  background: transparent;
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
}
