.course-sidebar-header {
  margin-bottom: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, #6F61F2 0%, #6357D9 100%);
  width: 100%;
  min-height: 56px;
  max-height: 56px;
  position: relative;
  transition: max-height 0.65s, border-radius 0.65s;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
}
.course-sidebar-header-expanded {
  max-height: 100%;
  border-radius: 8px 8px 16px 16px;
}
.course-sidebar-header-expanded .course-sidebar-header__menu-button,
.course-sidebar-header__toc-button {
  display: none;
}
.course-sidebar-header-expanded .course-sidebar-header__title {
  padding: 32px 0 0 0;
  white-space: normal;
  font-weight: 600;
}
.course-sidebar-header__menu-button {
  position: absolute;
  top: 16px;
  right: 20px;
}
.course-sidebar-header__expand-menu-button {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/menu_button_dots.svg) 50% 50% no-repeat;
  width: 37px;
  height: 37px;
  cursor: pointer;
  display: inline-block;
  transform: rotate(90deg);
  vertical-align: top;
  margin: 4px 4px 4px 16px;
  opacity: 0.5;
}
.course-sidebar-header__back-arrow {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/couse-back-arrow.svg) no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 20px;
  top: 20px;
  box-sizing: content-box;
  cursor: pointer;
}
.course-sidebar-header__title {
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0;
  box-sizing: border-box;
  padding: 0 54px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.65s;
}
.course-sidebar-header__widgets {
  position: absolute;
  right: 16px;
  top: 0;
  height: 100%;
  max-height: 56px;
  width: auto;
  box-sizing: content-box;
  font-size: 0;
}
.course-sidebar-header__progress {
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  margin-left: 0;
  margin-right: 22px;
  width: auto;
  height: 19px;
  font-size: 11px;
  line-height: 17px;
  color: #FFFFFF;
  cursor: pointer;
}
.course-sidebar-header__progress-icon {
  vertical-align: text-bottom;
  margin-right: 5px;
}
.course-sidebar-header__main-menu {
  text-align: left;
  font-size: 0;
  margin: 25px -4px 0;
}
.course-sidebar-header__main-menu-item {
  text-align: center;
  font-weight: 600;
  color: #FFFFFF;
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  transition: all 0.4s;
  margin: 4px;
}
.course-sidebar-header__main-menu-item:hover {
  background: rgba(255, 255, 255, 0.2);
}
.course-sidebar-header__main-menu-item:visited {
  color: #FFFFFF;
}
.course-sidebar-header__main-menu-item.primary {
  background: linear-gradient(180deg, #8CD100 0%, #66AF31 100%);
}
.course-sidebar-header > .course-progress {
  box-sizing: border-box;
  padding: 9px 107px 0 36px;
}
.course-sidebar-header .notifications_icon {
  margin: 17px 11px;
  vertical-align: top;
  pointer-events: initial;
}
@media (max-width: 760px) {
  .course-sidebar-header:not(.course-sidebar-header-expanded) {
    border-radius: 8px;
    padding: 5px;
    width: 36px;
    height: 36px;
    min-height: 36px;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
  }
  .course-sidebar-header__menu-button {
    top: 6px;
    right: 6px;
    pointer-events: initial;
  }
  .course-sidebar-header__toc-button {
    display: flex;
    position: fixed;
    max-width: fit-content;
    top: 10px;
    left: 10px;
    max-height: fit-content;
    pointer-events: initial;
  }
  .course-sidebar-header__title,
  .course-sidebar-header__back-arrow {
    display: none;
  }
  .course-sidebar-header-expanded .course-sidebar-header__title,
  .course-sidebar-header-expanded .course-sidebar-header__back-arrow {
    display: block;
  }
  .course-sidebar-header-expanded {
    border-radius: 0 0 16px 16px;
  }
}
