.transfer_progress_popup {
  background: linear-gradient(180deg, #8CD100 0%, #66AF31 100%);
  width: 255px;
  height: 255px;
  padding-top: 38px;
  box-sizing: border-box;
  border-radius: 50%;
  font-family: "PT Root UI", Arial, sans-serif;
  text-align: center;
}
.transfer_progress_popup__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer_progress_popup__checkmark {
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/checkmark.svg) no-repeat 50% 50%;
  background-size: 100%;
  width: 48px;
  height: 48px;
  margin: 0 auto;
}
.transfer_progress_popup__text {
  font-size: 36px;
  line-height: 36px;
  color: #FFFFFF;
  margin-top: 6px;
  height: 72px;
}
.transfer_progress_popup__button {
  cursor: pointer;
  display: inline-block;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 6px 16px;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #88CC00;
}
.transfer_progress_popup--null .transfer_progress_popup__text {
  font-size: 21px;
  line-height: 26px;
}
