.text-xxl {
  font-size: 32px;
  line-height: 40px;
}
.text-xl {
  font-size: 24px;
  line-height: 32px;
}
.text-l {
  font-size: 18px;
  line-height: 28px;
}
.text-m {
  font-size: 16px;
  line-height: 24px;
}
.text-s {
  font-size: 14px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 16px;
}
.text-xxs {
  font-size: 10px;
  line-height: 12px;
}
.module-access-info {
  display: flex;
  min-height: 100vh;
}
.module-access-info-cluster-cpm .module-access-info__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm.png);
}
.module-access-info-cluster-cpm .module-access-info__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-white.png);
}
.module-access-info-cluster-cpm .module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-small.svg);
}
.module-access-info-cluster-cpm .module-access-info__logo-white.module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-cpm-small-white.svg);
}
.module-access-info-cluster-sirius .module-access-info__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius.png);
}
.module-access-info-cluster-sirius .module-access-info__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-white.png);
}
.module-access-info-cluster-sirius .module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-small.svg);
}
.module-access-info-cluster-sirius .module-access-info__logo-white.module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-sirius-small-white.svg);
}
.module-access-info-cluster-textbook .module-access-info__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-textbook.png);
}
.module-access-info-cluster-textbook .module-access-info__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-textbook-white.png);
}
.module-access-info-cluster-textbook .module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-textbook-small.svg);
}
.module-access-info-cluster-textbook .module-access-info__logo-white.module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-textbook-small-white.svg);
}
.module-access-info-cluster-corp .module-access-info__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp.png);
}
.module-access-info-cluster-corp .module-access-info__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-white.png);
}
.module-access-info-cluster-corp .module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-small.svg);
}
.module-access-info-cluster-corp .module-access-info__logo-white.module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-corp-small-white.svg);
}
.module-access-info-cluster-univ .module-access-info__logo {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ.png);
}
.module-access-info-cluster-univ .module-access-info__logo-white {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-white.png);
}
.module-access-info-cluster-univ .module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-small.svg);
}
.module-access-info-cluster-univ .module-access-info__logo-white.module-access-info__logo-small {
  background-image: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/cluster-logo-univ-small-white.svg);
}
.module-access-info__logo {
  height: 36px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 196px;
  margin: 12px auto;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.module-access-info__logo-small {
  width: 35px;
}
.module-access-info__back-to {
  width: 24px;
  height: 24px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/auth_back_arrow.svg) center no-repeat;
  cursor: pointer;
  position: absolute;
  top: 16px;
  left: 16px;
}
.module-access-info__wrapper {
  gap: 16px;
}
.module-access-info__content {
  flex: 1 0 auto;
  gap: 40px;
  margin: 8px;
  padding-bottom: 40px;
  border-radius: 32px;
  background: #F7F7F7;
  color: #1A1A1A;
  align-items: center;
  text-align: center;
}
.module-access-info__content-pic {
  margin: 100px auto 0;
  min-height: 240px;
  width: 240px;
  background: url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/prof_cheops_3d_clock.png) center no-repeat;
}
.module-access-info__content-title {
  max-width: 560px;
  margin: 0 auto;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}
.module-access-info__content-text {
  max-width: 560px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  white-space: pre-wrap;
}
.module-access-info__button {
  min-width: 240px;
}
.module-access-info__footer {
  min-height: 80px;
}
@media (max-width: 760px) {
  .module-access-info__wrapper {
    width: 344px;
    padding: 0 16px;
  }
  .module-access-info__content-pic {
    margin: 40px auto 0;
    min-height: 180px;
    width: 180px;
    background-size: contain;
  }
  .module-access-info__content-title {
    width: 100%;
    font-size: 24px;
    line-height: 32px;
  }
  .module-access-info__content-text {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
  .module-access-info__button {
    width: 100%;
  }
  .module-access-info__footer {
    min-height: 56px;
  }
}
