.file_preview {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  margin: 15px;
  background: #ecf4ff;
  height: 170px;
  font-size: 24px;
  line-height: 20px;
  color: #7B29C9;
  text-decoration: none;
  text-transform: lowercase;
  max-width: 100%;
  overflow: hidden;
}
.file_preview img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.file_preview__close {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  top: -16px;
  right: -16px;
  cursor: pointer;
  background: #8C8695 url(/347e4c1c42194a73942851ed06937e9284512310/assets/img/close_cross.svg) 50% 50% no-repeat;
}
.file_preview__file_ext {
  padding: 16px;
  min-width: 150px;
  box-sizing: border-box;
  font-weight: 500;
}
